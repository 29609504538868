import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SideEnum } from 'libs/core/src/lib/enum/side.enum';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxComponent), multi: true }],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() set checked(value: boolean) {
    this.isChecked = value;
  }
  @Input() name: string = '';
  @Input() id: string = '';
  @Input() side: SideEnum = SideEnum.LEFT;
  @Input() disabled: boolean = false;

  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  sideEnum: typeof SideEnum = SideEnum;

  isChecked: boolean = false;

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(obj: any): void {
    this.isChecked = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onCheckboxChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.isChecked = input.checked;
    this.onChange(this.isChecked);
    this.changed.emit(this.isChecked);

    this.onTouched();
  }
}

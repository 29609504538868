import { Renderer2 } from '@angular/core';
import { cardType, GUID_LENGTH } from '../../app.const';
import { Guid } from '../helper/guid';
import { before } from 'lodash-es';

export function isNullOrEmpty(value: any): boolean {
  return !value || value.length === 0;
}

export function getNumberValueOrZero(value: number | null | undefined): number {
  return value ?? 0;
}

export function isExists(value: any) {
  return value != null && value !== 'undefined';
}

export function getStringValueOrEmpty(value: string | number | null | undefined): string {
  return isExists(value) ? String(value) : '';
}

export function getStringValueOrNull(value: string | number | null | undefined, allowEmptyValue = true): string | null {
  if (isExists(value)) {
    const result = String(value);
    if (result.length || allowEmptyValue) {
      return result;
    }
  }

  return null;
}

export function getBooleanValue(value: string | number | null | undefined): boolean {
  return Boolean(getStringValueOrEmpty(value));
}

export function getTopLevelDomain(url: string): string {
  let hostname;

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  if (url.indexOf('www.') > -0) {
    hostname = url.split('www.')[1];
  }

  hostname = hostname.split(':')[0];
  hostname = hostname.split('?')[0];

  hostname = hostname.split('.');

  if (hostname.length > 2) {
    hostname.shift();
  }

  return hostname.join('.');
}

export function use<T>(sourceValue: T, defaultValue: T): T {
  if (sourceValue === undefined) {
    return defaultValue;
  }

  return sourceValue;
}

export function isBitwiseFlag(value: number, flag: number) {
  return value & flag;
}

export function getCardTypeNamesOfBitwiseFlag(value: number): string[] {
  return Object.keys(cardType)
    .filter((key) => isBitwiseFlag(value, cardType[key].value))
    .map((key) => cardType[key].name as string);
}

export function getGuidOrNull(value: any): string {
  return value && typeof value === 'string' && Guid.isValid(value) ? value?.toLowerCase() : null;
}

export function getRandomIndex(len: number) {
  return Math.floor(Math.random() * len);
}

export function getRandomHash() {
  return getRandomIndex(1000000).toString().padStart(7, '0');
}

export function ifExists(value: string, collection: string[], resultIfEmptyCollection = false) {
  if (collection?.length) {
    return collection.map((el) => el.toLowerCase()).indexOf(value.toLowerCase()) > -1;
  }

  return resultIfEmptyCollection;
}

export function makeUrl(env: any, fileName: string): string {
  const isWebpageMode = env.app.mode === 'webpage';
  const isLocalhost = window.location.hostname === 'localhost' && window.location.port === '4200';

  const basePath =
    isWebpageMode || isLocalhost
      ? '.'
      : `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${env.app.assetsPath}`;

  return `${basePath}${fileName}`;
}

export function makeAhref(url: string, label: string, target: string = '_blank'): string {
  return `<a href="${url}" target="${target}">${label}</a>`;
}

export function sanitizeInput(input: string | null): string | null {
  let c: string | null = input;
  if (c !== null) {
    c = input.trim();
  }
  return c;
}

export function replaceNewLinesWithBr(text: string): string {
  if (!text) return text;
  return text.replace(/\n/g, '<br>');
}

export function loadScript(
  id: string,
  src: string,
  fnAfterLoad: (status: string) => void,
  async: boolean = true,
  defer: boolean = false,
  crossorigin: boolean = false,
  parentElement = null
): void {
  if (typeof document !== 'undefined') {
    const existingScript = document.getElementById(id);
    if (!existingScript) {
      let scriptElement = document.createElement('script');
      scriptElement.id = id;
      scriptElement.src = src;
      scriptElement.async = async;
      scriptElement.defer = defer;
      if (crossorigin) {
        scriptElement.crossOrigin = 'crossorigin';
      }
      scriptElement.onload = () => {
        console.log(`script ${src} was loaded`);
        if (fnAfterLoad) {
          fnAfterLoad('loaded');
        }
      };

      (parentElement ?? document.head).appendChild(scriptElement);
    } else {
      if (fnAfterLoad) {
        fnAfterLoad('failed');
      }
    }
  }
}

export function loadScriptSequentially(renderer: Renderer2, urls: { src: string; async: boolean }[], index: number = 0, fnAfterLoad: () => void) {
  if (index >= urls.length) {
    if (fnAfterLoad) {
      fnAfterLoad();
    }
    return;
  }

  const options = urls[index];
  const script = Object.assign(renderer.createElement('script'), options);
  script.onload = () => {
    console.log(`Script ${options.src} loaded`);
    loadScriptSequentially(renderer, urls, index + 1, fnAfterLoad);
  };

  renderer.appendChild(document.head, script);
}

export function loadScriptPromise(src: string, async: boolean = true): Promise<any> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = async;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export async function calculateHash(message: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);

  // Obliczenie hash SHA-384
  const hashBuffer = await crypto.subtle.digest('SHA-384', data);

  // Konwersja hash do stringa w formacie hex
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
}

export function getRandomNumber(scope: number = 1000): number {
  return Math.floor(Math.random() * scope);
}

export function buildMailToObject(params: { email?: string; label?: string; before?: string; after?: string }) {
  const label = params.label ?? params.email.split('?')[0] ?? '';
  return {
    email: params.email ?? '',
    label,
    before: params.before ?? '',
    after: params.after ?? '',
  };
}

export function getValueFromQueryParameters(verifiedParameters: string[], queryParams: { [key: string]: string }): string | null {
  for (const paramName of verifiedParameters) {
    if (paramName in queryParams) {
      return queryParams[paramName];
    }
  }

  return null;
}

export function getValidGuidOrNull(value: string | null): string | null {
  if (!value) {
    return null;
  }
  if (value.length < GUID_LENGTH) {
    return null;
  }

  const guid = value.length > GUID_LENGTH ? value.substring(0, GUID_LENGTH) : value;
  return Guid.isValid(guid) ? guid : null;
}

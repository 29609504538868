import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface ICustomErrorHandler {
  handleError(error: any): boolean;
}

export interface IResponseCustomErrorInterface {
  code: string;
  message: string;
  status?: number;
  properties?: any;
  errors?: any;
  details?: any;
}

export class CustomError extends Error implements IResponseCustomErrorInterface {
  code: string;
  status?: number;
  properties?: any;
  errors?: any;
  details?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private handlerCollection: Array<ICustomErrorHandler> = new Array<ICustomErrorHandler>();
  constructor(private translateService: TranslateService) {}

  public registerErrorHandler(handler: ICustomErrorHandler) {
    this.handlerCollection.push(handler);
  }

  public handle(error: any): boolean {
    return this.handlerCollection.some((handler) => handler.handleError(error));
  }

  public hasError(error: any | null, code: number) {
    return this.checkCode(error, code);
  }

  public getMessage(error: any | null, code: number = null) {
    return this.getError(error, code)?.message;
  }

  public getCode(error: any | null) {
    return this.getError(error)?.code;
  }

  public getError(error: any | null, code: number = null, status?: number): IResponseCustomErrorInterface {
    if (!error) {
      return null;
    }

    if (error.status) {
      status = error.status;
    }

    if (error.error) {
      return this.getError(error.error, code, status);
    }

    if (!this.checkCode(error, code)) {
      return null;
    }

    return this.createError(error, status);
  }

  private checkCode(error: any, code: number): boolean {
    if (error?.error) {
      return this.checkCode(error.error, code);
    }

    return error && error.code !== undefined && (code === null || error.code === code);
  }

  private createError(error, status) {
    const translateErrorKey = `errors.${error.code}`;
    const translatedMessage = this.translateService.instant(translateErrorKey);
    return Object.assign(
      { status: status, code: error.code.toString(), message: translatedMessage === translateErrorKey ? error.message : translatedMessage },
      error.properties ?? null,
      error.errors ?? null,
      error.details ?? null
    );
  }
}

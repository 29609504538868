<div class="order-tickets-summary-container px-3 px-lg-0">
  <div class="order-tickets-summary-block">
    <div class="row order-tickets-summary-block-head">
      <div class="col-8 col-md-6 text-left">
        {{ 'basket.list.ticket' | translate }}
      </div>
      <div class="col-2 col-md-3 col-lg-2 text-center d-none d-lg-block">
        {{ 'order.ticketsSummary.quantity' | translate }}
      </div>
      <div class="col-2 col-md-3 col-lg-2 text-center d-none d-md-block">
        {{ 'order.ticketsSummary.fee' | translate }}
      </div>
      <div class="col-4 col-md-3 col-lg-2 text-right c-price-header d-none d-md-block">
        {{ 'order.ticketsSummary.price' | translate }}
      </div>
      <div class="col-4 col-md-3 col-lg-2 text-right b1 font-weight-bold d-block d-md-none">
        {{ 'order.ticketsSummary.price' | translate }}
      </div>
    </div>
    <hr>
    <div>
      <ng-container *ngIf="orderSummaryView?.items?.length">
        <ng-container *ngFor="let item of orderSummaryView?.items; let i = index">
          <div class="row my-2" [ngClass]="{ 'discount': item.hasVoucher()}">
            <div class="col-8 col-md-6 b1">
              <span class="d-lg-none font-weight-bold">{{ item.quantity }} x</span> {{ item.ticket.name }} <span
                    class="d-none d-md-inline">({{ item.ticket.price | number:'1.2-2' }}{{ env.constants.currency }})</span>
              <p class="selected-seats m-0 font-weight-bold">
                <ng-container *ngIf="isLastPage">
                  <span *ngFor="let seat of item.ticketSymbols">
                    {{ 'ticketssummary.label' | translate: { row: seat[0], place: seat[1]} }}
                    <br>
                  </span>
                </ng-container>
              </p>
            </div>
            <div class="col-2 text-center b1 d-none d-lg-block">
              {{ item.quantity }}
            </div>
            <div class="col-md-3 col-lg-2 text-center b1 d-none d-md-block">
              {{ (item.ticket.requiredExtraFeesPrice * item.quantity) | number:'1.2-2' }} {{ env.constants.currency }}
            </div>
            <div class="col-4 col-md-3 col-lg-2 text-right b1 d-none d-md-block">
              {{((item.ticket.priceWithExtraFee) * item.quantity) | number:'1.2-2'}} {{ env.constants.currency }}
            </div>
            <div class="col-4 col-md-3 col-lg-2 text-right b1 font-weight-bold d-block d-md-none">
              {{((item.ticket.priceWithExtraFee) * item.quantity) | number:'1.2-2'}} {{ env.constants.currency }}
            </div>
          </div>
          <hr class="thin">
        </ng-container>
      </ng-container>

      <ng-container *ngIf="orderSummaryView?.optionalExtraFees?.length">
        <ng-container *ngFor="let item of orderSummaryView?.optionalExtraFees; let i = index">
          <div class="row" [ngClass]="{ 'mb-2': i === orderSummaryView?.optionalExtraFees.length - 1 }">
            <div class="col-8 col-md-6 b1">
              <span class="d-md-none font-weight-bold">{{order?.getCountOptionalFeeByGroupFee(item.id)}}
                x</span> {{ item.name }} <span class="d-none d-md-inline">({{ item.price | number:'1.2-2' }}
                {{ env.constants.currency }})</span>
            </div>
            <div class="col-2 text-center b1 d-none d-lg-block">
              {{order?.getCountOptionalFeeByGroupFee(item.id)}}
            </div>
            <div class="col-md-3 col-lg-2 text-center b1 d-none d-md-block">
              <!--$0.00-->
            </div>
            <div class="col-4 col-md-3 col-lg-2 text-right b1 d-none d-md-block">
              {{(item.price * order?.getCountOptionalFeeByGroupFee(item.id)) | number:'1.2-2'}}
              {{ env.constants.currency }}
            </div>
            <div class="col-4 col-md-3 col-lg-2 text-right b1 font-weight-bold d-block d-md-none">
              {{(item.price * order?.getCountOptionalFeeByGroupFee(item.id)) | number:'1.2-2'}}
              {{ env.constants.currency }}
            </div>
          </div>
          <hr class="thin">
        </ng-container>
      </ng-container>

      <ng-container *ngIf="orderSummaryView?.cateringItems?.length">
        <ng-container *ngFor="let item of orderSummaryView.cateringItems; let i = index">
          <div class="row" [ngClass]="{ 'mb-2': i === orderSummaryView.cateringItems.length - 1 }">
            <div class="col-8 col-md-6 b1">
              <span class="d-md-none font-weight-bold">{{ item.quantity }}
                x</span> {{ item.articleCombination.name }} <span
                    class="d-none d-md-inline">({{item.unitPriceWithoutSeparatedItems | number:'1.2-2'}}
                {{ env.constants.currency }})</span>
              <span *ngIf="item.description" class="d-block fb-item-description">
                {{ item.description }}
              </span>
            </div>
            <div class="col-2 text-center b1 d-none d-md-block text-center">
              {{ item.quantity }}
            </div>
            <div class="col-2 text-center b1 d-none d-md-block">
              <!--$0.00-->
            </div>
            <div class="col-4 col-md-3 col-lg-2 text-right b1 d-none d-md-block">
              {{ item.price | number:'1.2-2'}} {{ env.constants.currency }}
            </div>
            <div class="col-4 col-md-3 col-lg-2 text-right b1 font-weight-bold d-block d-md-none">
              {{ item.price | number:'1.2-2'}} {{ env.constants.currency }}
            </div>
            <ng-container *ngFor="let separated of item.articleCombination.modifierArticleList">
              <ng-container *ngIf="separated.separateItem">
                <ng-container *ngFor="let separatedItem of separated.itemCollection">
                  <div class="col-8 col-md-6 b1">
                    <span class="d-md-none font-weight-bold">{{ separatedItem.quantity * item.quantity }}
                      x </span>
                    {{ separatedItem.name }}
                    <span
                          class="d-none d-md-inline">({{ (separatedItem.price * separatedItem.quantity) | number:'1.2-2' }}
                      {{ env.constants.currency }})</span>
                  </div>
                  <div class="col-2 text-center b1 d-none d-md-block text-center">
                    {{ separatedItem.quantity * item.quantity }}
                  </div>
                  <div class="col-2 text-center b1 d-none d-md-block">
                    <!--$0.00-->
                  </div>
                  <div class="col-4 col-md-3 col-lg-2 text-right b1 d-none d-md-block">
                    {{ (separatedItem.price * item.quantity * separatedItem.quantity) | number:'1.2-2' }}
                    {{ env.constants.currency }}
                  </div>
                  <div class="col-4 col-md-3 col-lg-2 text-right b1 font-weight-bold d-block d-md-none">
                    {{ (separatedItem.price * item.quantity * separatedItem.quantity) | number:'1.2-2' }}
                    {{ env.constants.currency }}
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          <hr class="thin">
        </ng-container>
      </ng-container>

      <ng-container *ngIf="orderSummaryView?.giftCardItems?.length">
        <ng-container *ngFor="let card of orderSummaryView.giftCardItems; let i = index">
          <div class="row my-2">
            <div class="col-8 col-md-6 b1">
              <span class="d-lg-none font-weight-bold">{{ card.quantity }} x</span>
              {{ card.name }}
            </div>
            <div class="col-2 col-md-3 col-lg-2 text-center b1 d-none d-lg-block text-center">
              {{ card.quantity }}
            </div>
            <div class="col-2 col-md-3 col-lg-2 text-center b1 d-none d-md-block"></div>
            <div class="col-4 col-md-3 col-lg-2 text-right b1 d-none d-md-flex justify-content-end pl-0"
                 style="gap:0.25rem">
              {{ card.price | number:'1.2-2'}} {{ env.constants.currency }}
              <span *ngIf="!isLastPage">
                <i class="color-orange fa fa-plus rotate-45 pointer" (click)="removeGroupOfCards(card)"></i>
              </span>
            </div>
            <div class="col-4 col-md-3 col-lg-2 text-right b1 font-weight-bold d-block d-md-none">
              {{ card.price | number:'1.2-2'}} {{ env.constants.currency }}
              <span *ngIf="!isLastPage">
                <i class="color-orange fa fa-plus rotate-45 pointer" (click)="removeGroupOfCards(card)"></i>
              </span>
            </div>
          </div>
          <hr class="thin">
        </ng-container>
      </ng-container>

      <ng-container *ngIf="order?.defaultExtraFees?.length">
        <ng-container *ngFor="let item of order.defaultExtraFees; let i = index">
          <div class="row" [ngClass]="{ 'mb-2': i === order.defaultExtraFees.length - 1 }">
            <div class="col-8 col-md-6 b1">
              {{ item.defaultExtraFeeName }}
            </div>
            <div class="col-2 text-center b1 d-none d-md-block">
            </div>
            <div class="col-2 text-center b1 d-none d-md-block">
            </div>
            <div class="col-4 col-md-3 col-lg-2 text-right b1 d-none d-md-block">
              {{ item.defaultExtraFeePrice | number:'1.2-2' }} {{ env.constants.currency }}
            </div>
            <div class="col-4 col-md-3 col-lg-2 text-right b1 font-weight-bold d-block d-md-none">
              {{ item.defaultExtraFeePrice | number:'1.2-2' }} {{ env.constants.currency }}
            </div>
          </div>
          <hr class="thin">
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!isLastPage && order?.getCardPaymentMethods()?.length > 0">
        <ng-container *ngFor="let payment of order?.getCardPaymentMethods()">
          <div class="row mt-2 b1 fs-1">
            <div class="col-6">
              {{payment.name}}
            </div>
            <div class="col-6 text-right">
              -{{payment.value | number:'1.2-2'}} {{ env.constants.currency }}
              <span *ngIf="!isLastPage && order.status !== 1">
                <i class="color-orange fa fa-plus rotate-45 pointer" (click)="removeInternalPaymentMethod(payment)"></i>
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!isLastPage && order?.getVouchers()?.length > 0">
        <ng-container *ngFor="let voucher of order.getVouchers()">
          <div class="row mt-2 b1 fs-1">
            <div class="col-6">
              {{voucher.name}}
            </div>
            <div class="col-6 text-right">
              <span *ngIf="!isLastPage && order.status !== 1">
                <i class="color-orange fa fa-plus rotate-45 pointer" (click)="removeUsedVoucher(voucher)"></i>
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="row " *ngIf="order?.status != 5">
    <div class="col-12 text-right font-weight-bold to-pay pt-3">
      {{ 'order.ticketsSummary.' + (order?.status == 4 ? 'paid' : 'totalBrutto') | translate }}
      {{ (order?.status == 4 ? orderSummaryView.totalPrice : order?.getRealValueToPay()) | number:'1.2-2' }}
      {{ env.constants.currency }}
    </div>
    <div class="col-12">
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
  </div>
  <ng-content></ng-content>
</div>
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { PaymentProviderStateService } from '../../../service/payment-provider-state.service';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { OrderPaymentPackage } from '../../../../../../../../core/src/lib/model/order-payment.package.model';
import { BasysInternalPaymentMethodType } from './model/basys-internal-payment-method-type';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';

@Component({
  selector: 'app-payment-provider-basys-component',
  templateUrl: './basys-payment-provider.component.html',
})
export class BasysPaymentProviderComponent implements PaymentProviderComponentInterface, OnInit, OnDestroy {
  public constructor(public paymentProviderStateService: PaymentProviderStateService) {}

  public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();
  public basysForm: FormGroup;
  private continueUrl: string;
  public env;
  public cardType: string = null; // master_card / visa

  public checkCardCompany(): void {
    const cardNumber = this.basysForm.get('cardNumber').value;

    switch (true) {
      case cardNumber.match(new RegExp('^4')) != null:
        this.cardType = 'visa';
        break;
      case /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(cardNumber):
        this.cardType = 'master_card';
        break;
      default:
        this.cardType = null;
    }
  }

  public ngOnInit(): void {
    this.basysForm = this.paymentProviderStateService.getBasysFormGroup();
    Object.keys(this.basysForm.controls).forEach((key) => {
      this.basysForm.controls[key].setValue(null);
    });
  }

  public ngOnDestroy(): void {}

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    window.location.href = this.continueUrl;
  }

  public formatExpirationDate(): void {
    const value = this.basysForm.get('expiration').value.replace(new RegExp(/[^\d]/, 'g'), '');
    this.basysForm.get('expiration').setValue(value);

    if (!/^\d{0,2}\/?\d{0,2}$/.test(value)) {
      return;
    }

    if (/^\d{3,}$/.test(value)) {
      this.basysForm.get('expiration').setValue(value.match(new RegExp('.{1,2}', 'g')).join('/'));
    }
  }

  public onPreInitPayment(event: OrderPaymentPackage): Observable<OrderPaymentPackage> {
    this.paymentProviderStateService.formSubmitAttempt = true;
    this.continueUrl = event.continueUrl;

    return new Observable<OrderPaymentPackage>((subscriber) => {
      if (this.basysForm.valid) {
        event.intPayMethodType = BasysInternalPaymentMethodType.card;
        event.intPayMethodValue = btoa(
          Object.entries(this.basysForm.controls)
            .map(([x, y]) => y.value.replace(/\s/g, ''))
            .join(';')
        );
      } else {
        event.abort = true;
      }
      subscriber.next(event);
      subscriber.complete();
    });
  }
}

import { Component } from '@angular/core';
import { SideEnum } from 'libs/core/src/lib/enum/side.enum';

@Component({
  selector: 'app-ui-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class UITestComponent {
  side: SideEnum = SideEnum.LEFT;

  onSideChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.side = selectElement.value as SideEnum;
  }
}

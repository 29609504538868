import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';
import { WeekdayService } from '../service/weekday.service';

@Pipe({
  name: 'dateTime',
})
export class DateTimePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: string, private translateService: TranslateService, private weekdayService: WeekdayService) {}

  transform(value: DateTime | string | number, format: string, relativeCalendarDays: number = 0): string {
    let v: DateTime;
    switch (typeof value) {
      case 'string':
        v = DateTime.fromISO(value);
        break;
      case 'number':
        v = DateTime.fromMillis(value);
        break;
      default:
        v = value;
    }

    if (!(v instanceof DateTime)) {
      return;
    }

    value = v.setLocale(this.localeId);
    const preText = (value) => {
      const excludedFormats = ['MONTH_SHORT', 'MONTH_WIDE', 'MONTH_YEAR_WIDE'];
      if (relativeCalendarDays === 0 || excludedFormats.includes(format)) {
        return '';
      }

      const now = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      if (value >= now && now >= value.minus({ days: relativeCalendarDays })) {
        return this.capitalized(value.toRelativeCalendar({ locale: this.localeId })) + ', ';
      }

      return '';
    };

    return preText(value) + this.getFormat(value, format);
  }

  getFormat(value: DateTime, format: string) {
    const customWeekdayFormat = (value: any, originFormat: string, replacer: string = 'cccc') => {
      if (!originFormat) {
        return value;
      }

      const index = originFormat.indexOf(replacer);
      const v = index < 0 ? '' : this.weekdayService.getCustomDayName(value);
      if (!v.length) {
        return value.toFormat(originFormat);
      }

      const s = originFormat.replace(replacer, '');
      return [value.toFormat(s.slice(0, index)), v, value.toFormat(s.slice(index))].join('');
    };

    switch (format) {
      case 'DAY_SHORT':
        return value.toFormat('ccc');
      case 'MONTH_SHORT':
        return value.toFormat('MMM');
      case 'MONTH_WIDE':
        return value.toFormat('MMMM');
      case 'MONTH_YEAR_WIDE':
        return value.toFormat('MMMM yyyy');
      case 'DATE_DMY':
        return value.toFormat('dd LLL yyyy');
      case 'DATE_DMY_FULL':
        return value.toFormat('dd LLLL yyyy');
      case 'DATE_MDY':
        return value.toFormat('d.M.y');
      case 'FULL_DATE':
        return customWeekdayFormat(value, 'cccc, LLLL d, yyyy');
      case 'DATE_HUMAN':
        return customWeekdayFormat(value, 'cccc dd.LL.yyyy');
      case 'DATE_HUMAN_COMA':
        return customWeekdayFormat(value, 'cccc, dd.LL.yyyy');
      case 'DATE_HUMAN_COMA_MDY':
        return customWeekdayFormat(value, 'cccc, LL/dd/yyyy');
      case 'DATE_HUMAN_COMA_SHORT':
        return value.toFormat('ccc, dd.LL.yyyy');
      case 'DATE_SHORT_DM':
        return value.toFormat('ccc, dd.LL.');
      case 'DATE_SHORT_MDY':
        return value.toFormat('LLL. d, y');
      case 'DATE_SHORT_DY':
        return value.toFormat('MMM d, y');
      case 'DATE_SHORT_DMY':
        return value.toFormat('dd.LL.yyyy');
      case 'DATE_SHORT_MDY_SLASH':
        return value.toFormat('LL/dd/yyyy');
      case '12H':
        return value.toFormat('hh:mm a');
      case 'MIN_12H':
        return value.toFormat('h:mm a');
      case '24H':
        return value.toFormat('HH:mm');
      case 'FULL_DATE_DAY_SHORT':
        return value.toFormat('ccc d.M.y');
      case 'MEDIUM_DATE':
        return value.toFormat('d MMM y');
      case 'LONG_DATE':
        return value.toFormat('d MMMM y');
      case 'PAYMENT_CARD_EXPIRED':
        return value.toFormat('MM/yy');
      case 'LOCAL_STRING_DATE_FULL':
        return value.toLocaleString(DateTime.DATE_FULL);
      case 'LOCAL_STRING_DATE_HUGE':
        return value.toLocaleString(DateTime.DATE_HUGE);
      case 'LOCAL_STRING_TIME_24_SIMPLE':
        return value.toLocaleString(DateTime.TIME_24_SIMPLE);
      default:
        return customWeekdayFormat(value, format);
    }
  }

  capitalized(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
}

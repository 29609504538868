<div class="m-0">
  <div class="p-0 px-0">
    <div class="text-center col-12 p-0">
      <app-steps [step]="1"></app-steps>
    </div>
  </div>

  <div class="container">
    <div class="main-screen-container">
      <div [smooth-in]="loadingService.isLoaded(loaderEnum.MAIN)">
        <ng-container *ngIf="screen">
          <div class="row">
            <div class="col-12 m-3">
              <app-simple-countdown></app-simple-countdown>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-10">
              <div class="row">
                <div class="col-12 col-md-4 col-lg-3">
                  <app-book-movie-list [screen]="screen" [cinemaId]="cinemaId"></app-book-movie-list>
                </div>
                <div #content class="_content col-12 col-md-8 col-lg-9 screen-col p-0 auditorium">

                  <div class="col-screen">
                    <div style="min-height: 50px;">
                      <app-message [custom]="true"></app-message>
                    </div>
                    <pinch-zoom #pinch [disablePan]="false" [autoHeight]="false" backgroundColor="ffffff"
                                [autoZoomOut]="false" class="_pinch">
                      <div #auditorium class="_auditorium">
                        <div class="screen-header-container screen-reflections mt-3 mb-3">
                          <div class="screen-header">{{ 'screen.header.hall' | translate }}</div>
                        </div>
                        <app-screen-seats #screenSeats *ngIf="!screen?.generalAdmission" dir="ltr" [screen]="screen"
                                          [limit]="10" [seatIds]="seatIds"
                                          [priceSeparatorsEnabled]="priceSeparatorsEnabled"
                                          [rowSeparators]="rowSeparators" (seatsSelectedEvent)="onSeatsSelected($event)"
                                          (seatPopupShowEvent)="onShowedSeatPopup($event)"
                                          [shouldShowPopupOnSelectedSeat]="shouldShowPopupOnSelectedSeat"
                                          (lastSelectedSeatChange)="lastSelectedSeatChange($event)"
                                          [clickedSeatGroupTypes]="screenStateService.state.showSeatGroupPopups">
                        </app-screen-seats>
                      </div>
                    </pinch-zoom>
                  </div>
                  <div class="row">
                    <div class="col-12 screen-seats-container my-4" [class]="'size-'+screen?.pseats[0].length">
                      <app-screen-legend [screen]="screen"></app-screen-legend>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-2 chosen-col">
              <app-sidebar>
                <div sidebar-title>
                  <div class="chosen-sidebar-header">{{ 'screen.seatsSelectedList.label' | translate }}</div>
                </div>
                <div sidebar-body>
                  <div class="chosen-sidebar-body">
                    <ng-container *ngIf="!screen.generalAdmission">
                      <ng-container *ngIf="seatIds && seatIds.length > 0 else emptySeatSelectedTemplate">
                        <app-screen-seats-selected-list [screen]="screen"
                                                        [seatIds]="seatIds"></app-screen-seats-selected-list>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </app-sidebar>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-12 col-md-10">
              <div class="row">
                <div class="col-12 mt-4">
                  <app-screen-navigation (navigationEvent)="onNavigationClick($event)" [screeningId]="screeningId"
                                         [isLoadingData]="isLoadingData"
                                         [isNextButtonDisabled]="isLoadingData || isNextButtonDisabled()"
                                         [totalPrice]="ticketPrice">
                  </app-screen-navigation>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<app-popup [isVisible]="showSelectedSeatModal" (closeButtonClicked)="onClickedCloseModal(false)">
  <h4 header [innerHTML]="'screen.seats.chosenSeatType' | translate" class="p-0"></h4>
  <div class="row">
    <div class="col-12">

      <div class="question">
        <ng-container *ngIf="lastSelectedSeat && rowSeparators[lastSelectedSeat.rowNumber]">
          <ng-container
                        *ngIf="lastSelectedSeat.transformGroupTypesToString().toLocaleLowerCase().trim() !== 'none' else noneTransformGroupTypesTemplate">
            <span class="popup-ticket-type">
              {{ lastSelectedSeat.transformGroupTypesToString() | titlecase }}
            </span>
          </ng-container>
          <ng-template #noneTransformGroupTypesTemplate>
            <span class="popup-ticket-type">
              {{ lastSelectedSeat.defaultSeatGroupName && lastSelectedSeat.defaultSeatGroupName.length > 0 ? lastSelectedSeat.defaultSeatGroupName : 'Standard' }}
            </span>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="lastSelectedSeat !== null && lastSelectedSeat.groupDescriptionCollection">
          <ng-container *ngIf="lastSelectedSeat.groupDescriptionCollection.length > 0 else noGroupDescriptionTemplate">
            <div *ngFor="let description of lastSelectedSeat.groupDescriptionCollection" [innerHTML]="description">
            </div>
          </ng-container>
          <ng-template #noGroupDescriptionTemplate>
            <div [innerHTML]="lastSelectedSeat.defaultGroupDescription"></div>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
  <div footer>
    <button class="btn btn-next" (click)="onClickedCloseModal(false)">{{ 'shared.ok' | translate }}</button>
  </div>
</app-popup>

<ng-template #emptySeatSelectedTemplate>
  <span>
    {{ 'screen.seatsSelectedList.nothingSelected' | translate }}
  </span>
</ng-template>
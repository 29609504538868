<h1>TEST components</h1>
<div class="d-flex gap-1">
  <ng-container [ngTemplateOutlet]="properties"></ng-container>
</div>

<h2>UI</h2>
<div class="d-flex flex-wrap gap-1">
  <ng-container [ngTemplateOutlet]="appRadio"></ng-container>
  <ng-container [ngTemplateOutlet]="appCheckbox"></ng-container>
  <ng-container [ngTemplateOutlet]="appNumericStepper"></ng-container>
  <ng-container [ngTemplateOutlet]="appDateTimePicker"></ng-container>
  <ng-container [ngTemplateOutlet]="appDatePicker"></ng-container>
  <ng-container [ngTemplateOutlet]="appTabs"></ng-container>
</div>


<h2>Others</h2>
<div class="d-flex flex-wrap gap-1">
</div>

<ng-template #properties>
  <div class="card">
    <h6>Properties</h6>
    <div>
      <label for="sideSelector">Select Side:</label>
      <select id="sideSelector" [(ngModel)]="side" (change)="onSideChange($event)">
        <option value="left">Left</option>
        <option value="right">Right</option>
      </select>
    </div>
  </div>
</ng-template>

<ng-template #appRadio>
  <div class="card component">
    <h6>Radio buton</h6>
    <small>app-radio</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-radio id="radio1" name="radio_UITest_1" [side]="side" [value]="1" [disabled]="true">Disabled
        Radio</app-radio>
      <app-radio id="radio3" name="radio_UITest_1" [side]="side" [value]="2" [disabled]="true" [checked]="true">Disabled
        Checked Radio</app-radio>
      <app-radio id="radio2" name="radio_UITest_2" [side]="side" [value]="1">Acive Radio</app-radio>
      <app-radio id="radio4" name="radio_UITest_2" [side]="side" [value]="2" [checked]="true">Acive Checked
        Radio</app-radio>
    </div>
  </div>
</ng-template>

<ng-template #appCheckbox>
  <div class="card component">
    <h6>Checkbox</h6>
    <small>app-checkbox</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-checkbox id="checkbox1" name="checkbox_UITest" [side]="side" [disabled]="true">Disabled
        Checkbox</app-checkbox>
      <app-checkbox id="checkbox3" name="checkbox_UITest" [side]="side" [disabled]="true" [checked]="true">Disabled
        Checked Checkbox</app-checkbox>
      <app-checkbox id="checkbox2" name="checkbox_UITest" [side]="side">Active Checkbox</app-checkbox>
      <app-checkbox id="checkbox4" name="checkbox_UITest" [side]="side" [checked]="true">Active Checked
        Checkbox</app-checkbox>
    </div>
  </div>
</ng-template>


<ng-template #appNumericStepper>
  <div class="card component">
    <h6>Numeric stepper</h6>
    <small>app-numeric-stepper</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-numeric-stepper quantity="10" [disabled]="true"></app-numeric-stepper>
      <app-numeric-stepper quantity="5" [inputField]="true" [disabled]="true"></app-numeric-stepper>

      <app-numeric-stepper></app-numeric-stepper>
      <app-numeric-stepper [inputField]="true" [selectOnFocus]="true"></app-numeric-stepper>
    </div>
  </div>
</ng-template>

<ng-template #appDateTimePicker>
  <div class="card component">
    <h6>Date Time picker</h6>
    <small>app-datetime-picker</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-datetime-picker></app-datetime-picker>
    </div>
  </div>
</ng-template>

<ng-template #appDatePicker>
  <div class="card component">
    <h6>Date picker</h6>
    <small>app-date-picker</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-date-picker></app-date-picker>
    </div>
  </div>
</ng-template>

<ng-template #appTabs>
  <div class="card component">
    <h6>Tabs</h6>
    <small>app-tabs</small>
    <div class="d-flex flex-column gap-1 mt-3">
      <app-tabs>
        <ng-container
                      *ngFor="let tab of [{title:'Tab 1', active: false}, {title:'Tab 2', active: true}, {title:'Tab 3', active: true}]">
          <app-tab [title]="tab.title" [active]="tab.active"></app-tab>
        </ng-container>
      </app-tabs>
    </div>
  </div>
</ng-template>
<div class="order-tickets-summary-container px-3 px-lg-0">
  <div class="order-tickets-summary-block" *ngIf="orderSummaryView">
    <div class="row order-tickets-summary-block-head">
      <div class="col c-title">{{ 'basket.list.ticket' | translate }}</div>
      <div class="col-2 text-center">
        {{ 'order.ticketsSummary.quantity' | translate }}
      </div>
      <div class="col-2 text-center d-none d-md-block">
        {{ 'order.ticketsSummary.fee' | translate }}
      </div>
      <div class="col-4 col-md-3 text-right c-price-header">
        {{ 'order.ticketsSummary.price' | translate }}
      </div>
    </div>
    <hr class="thin">
    <div class="pt-0 b1">
      <ng-container *ngIf="orderSummaryView?.items?.length">
        <ng-container *ngFor="let item of orderSummaryView.items; let i = index">
          <div class="row mt-2">
            <div class="col">
              {{ item.ticket.name }}
            </div>
            <div class="col-2 text-center">
              {{ item.quantity }}
            </div>
            <div class="col-2 text-center d-none d-md-block text-nowrap">
              {{ (item.ticket.requiredExtraFeesPrice * item.quantity) | number:'1.2-2' }} {{ env.constants.currency }}
            </div>
            <div class="col-4 col-md-3 text-right d-none d-md-block text-nowrap">
              {{((item.ticket.priceWithExtraFee) * item.quantity) | number:'1.2-2'}} {{ env.constants.currency }}
            </div>
            <div class="col-4 col-md-3 text-right font-weight-bold d-block d-md-none text-nowrap">
              {{((item.ticket.priceWithExtraFee) * item.quantity) | number:'1.2-2'}} {{ env.constants.currency }}
            </div>
          </div>
        </ng-container>
        <hr class="thin">
      </ng-container>
      <ng-container *ngIf="orderSummaryView?.optionalExtraFees?.length">
        <ng-container *ngFor="let item of orderSummaryView.optionalExtraFees; let i = index">
          <div class="row mt-2">
            <div class="col-6 col-md-5">
              {{ item.name }} <span class="d-none d-md-inline text-nowrap">({{item.price| number:'1.2-2'}}
                {{ env.constants.currency }})</span>
            </div>
            <div class="col-2 text-center d-block p-0">
              <span class="increment" *ngIf="couldChangeOptionalFees" (click)="doDecrement(item)">
                <i class="fa fa-minus"></i>
              </span>
              {{order?.getCountOptionalFeeByGroupFee(item.id)}}
              <span class="increment" *ngIf="couldChangeOptionalFees" (click)="doIncrement(item)">
                <i class="fa fa-plus"></i>
              </span>
            </div>
            <div class="col-2 text-center d-none d-md-block">
              <!--$0.00-->
            </div>
            <div class="col-4 col-md-3 text-right d-none d-md-block text-nowrap">
              {{(item.price * order?.getCountOptionalFeeByGroupFee(item.id)).toFixed(2)}}
              {{ env.constants.currency }}
            </div>
            <div class="col-4 col-md-3 text-right font-weight-bold d-block d-md-none text-nowrap">
              {{(item.price * order?.getCountOptionalFeeByGroupFee(item.id)).toFixed(2)}}
              {{ env.constants.currency }}
            </div>
          </div>
        </ng-container>
        <hr class="thin">
      </ng-container>
      <ng-container *ngIf="orderSummaryView?.cateringItems?.length">
        <ng-container *ngFor="let item of orderSummaryView.cateringItems; let i = index">
          <div class="row" [ngClass]="{ 'mb-2': i === orderSummaryView.cateringItems.length - 1 }">
            <div class="col-8 col-md-5">
              <span class="d-md-none">
                <span class="increment" (click)="onDecrementCatering(item)"><img src="/assets/images/minus.png"
                       alt="minus"></span>
                {{ item.quantity }}
                <span class="increment" (click)="onIncrementCatering(item)"><img src="/assets/images/plus.png"
                       alt="plus"></span>
              </span>
              {{ item.articleCombination.name }} <span
                    class="d-none d-md-inline text-nowrap">({{item.unitPriceWithoutSeparatedItems | number:'1.2-2'}}
                {{ env.constants.currency }})</span>
              <span *ngIf="item.description" class="d-block text-dark fb-item-description">
                {{ item.description }}
              </span>
              <span *ngIf="!item.description" class="d-block text-dark fb-item-description">&nbsp;</span>
            </div>
            <div class="col-2 col-md-3 text-center d-none d-md-block">
              <span class="increment" (click)="onDecrementCatering(item)"><img src="/assets/images/minus.png"
                     alt="minus"></span>
              {{ item.quantity }}
              <span class="increment" (click)="onIncrementCatering(item)"><img src="/assets/images/plus.png"
                     alt="plus"></span>
            </div>
            <div class="col-2 text-center d-none d-md-block">
              <!--$0.00-->
            </div>
            <div class="col-4 col-md-2 text-right d-none d-md-block text-nowrap">
              {{item.price | number:'1.2-2'}} {{ env.constants.currency }}
            </div>
            <div class="col-4 col-md-2 text-right font-weight-bold d-block d-md-none text-nowrap">
              {{item.price | number:'1.2-2'}} {{ env.constants.currency }}
            </div>
            <ng-container *ngFor="let separated of item.articleCombination.modifierArticleList">
              <ng-container *ngIf="separated.separateItem">
                <ng-container *ngFor="let separatedItem of separated.itemCollection">
                  <div class="col-8 col-md-5">
                    <span class="d-md-none font-weight-bold">{{ separatedItem.quantity * item.quantity }}
                      x </span>
                    {{ separatedItem.name }}
                    <span
                          class="d-none d-md-inline text-nowrap">({{ (separatedItem.price * separatedItem.quantity) | number:'1.2-2' }}
                      {{ env.constants.currency }})</span>
                  </div>
                  <div class="col-2 col-md-3 text-center d-none d-md-block">
                    {{ separatedItem.quantity * item.quantity }}
                  </div>
                  <div class="col-2 text-center d-none d-md-block">
                    <!--$0.00-->
                  </div>
                  <div class="col-4 col-md-2 text-right d-none d-md-block text-nowrap">
                    {{ (separatedItem.price * item.quantity * separatedItem.quantity) | number:'1.2-2' }}
                    {{ env.constants.currency }}
                  </div>
                  <div class="col-4 col-md-2 text-right font-weight-bold d-block d-md-none text-nowrap">
                    {{ (separatedItem.price * item.quantity * separatedItem.quantity) | number:'1.2-2' }}
                    {{ env.constants.currency }}
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <hr class="thin">
      </ng-container>
      <ng-container *ngIf="order?.defaultExtraFees?.length">
        <ng-container *ngFor="let item of order.defaultExtraFees">
          <div class="row" [ngClass]="{ 'mb-2': i === order.defaultExtraFees.length - 1 }">
            <div class="col-8 col-md-5">
              {{ item.defaultExtraFeeName }}
            </div>
            <div class="col-2 col-md-3 text-center d-none d-md-block">
            </div>
            <div class="col-2 text-center d-none d-md-block">
            </div>
            <div class="col-4 col-md-2 text-right d-none d-md-block text-nowrap">
              {{ item.defaultExtraFeePrice | number:'1.2-2' }} {{ env.constants.currency }}
            </div>
            <div class="col-4 col-md-2 text-right font-weight-bold d-block d-md-none text-nowrap">
              {{ item.defaultExtraFeePrice | number:'1.2-2' }} {{ env.constants.currency }}
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="row mt-3 justify-content-end">
        <div class="col text-right font-weight-bold d-none d-md-block to-pay">
          {{ 'order.ticketsSummary.totalBrutto' | translate }}
          <span class="text-nowrap">
            {{(orderSummaryView?.totalPrice + this.getTotalOptionalExtraFee()) | number:'1.2-2'}}
            {{ env.constants.currency }}
          </span>
        </div>
        <div class="col text-right font-weight-bold d-block d-md-none to-pay">
          {{ 'order.ticketsSummary.totalBrutto' | translate }} &nbsp;
          <span class="text-nowrap">
            {{(orderSummaryView?.totalPrice + this.getTotalOptionalExtraFee()) | number:'1.2-2'}}
            {{ env.constants.currency }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { RedirectionService } from 'libs/core/src/lib/service/redirection-service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { environment } from '../environments/environment';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ScreenDataProvider } from 'libs/core/src/lib/data-provider/screen.data-provider';
import { take } from 'rxjs';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { StepsService } from 'libs/core/src/lib/service/steps.service';
import { FlowType } from 'libs/core/src/lib/model/component/steps/flow-type.model';
import { HttpParams } from '@angular/common/http';
import { AppComponent, TranslationService } from '@lib/core';

@Component({
  selector: 'app-positivecinema',
  templateUrl: './app.component.html',
})
export class CustomAppComponent extends AppComponent {
  loaderEnum: typeof LoaderEnum = LoaderEnum;

  @ViewChild('loginPopupTemplate') loginModal: TemplateRef<any>;

  constructor(
    protected translationService: TranslationService,
    protected title: Title,
    protected redirectionService: RedirectionService,
    protected stateService: StateService,
    private authStateService: AuthStateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private screenDataProvider: ScreenDataProvider,
    private stepsService: StepsService
  ) {
    super(environment, translationService, title, redirectionService, stateService);
  }

  public ngOnInit(): void {
    this.authStateService.retrieveUserToken();

    this.router.events.subscribe((event) => {
      this.title.setTitle(environment.metadata.title);

      if (event instanceof NavigationStart && event.url.startsWith('/screen?')) {
        const cinemaId = this.getParamValueQueryString(event.url, 'cinemaId');
        const screeningId = this.getParamValueQueryString(event.url, 'screeningId');

        if (cinemaId && screeningId) {
          this.setFlowType(cinemaId, screeningId);
        }
      }
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params?.signin === 'true' && !this.authStateService.userIsLoggedAndTokenIsValid()) {
        this.modalService.show(this.loginModal, {});
      }

      // if (params?.cinemaId && params?.screeningId && this.router.routerState.snapshot?.url.startsWith('/screen?')) {
      //   this.setFlowType(params.cinemaId, params.screeningId);
      // }
    });
  }

  getParamValueQueryString(url, paramName) {
    let paramValue;

    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }

    return paramValue;
  }

  private getPageIdentity() {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.data['pageIdentify'] || undefined;
  }

  private setFlowType(cinemaId, screeningId) {
    this.screenDataProvider.getScreenByAndCheckIfScreeningIsEvent(cinemaId, screeningId).subscribe((data: ScreenViewModel) => {
      this.stepsService.FlowType = data?.generalAdmission ? FlowType.Tickets : FlowType.Standard;
      if (this.stepsService.FlowType === FlowType.Tickets) {
        this.router.navigate(['tickets'], { queryParams: { screeningId: screeningId, cinemaId: cinemaId } });
      }
    });
  }
}

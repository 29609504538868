import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { UserLoyaltyPopupTypeEnum } from 'libs/core/src/lib/model/enum/user-loyalty-popup-type';
import { AccountItemsViewModel } from 'libs/core/src/lib/model/view-model/account-items/account-items.view.model';
import { UserViewModel } from 'libs/core/src/lib/model/view-model/user/user.view.model';
import { CardPaymentService } from 'libs/core/src/lib/service/card-payment.service';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoyaltyService } from 'libs/core/src/lib/service/loyalty.service';
import { NavigationService } from 'libs/core/src/lib/service/navigation/navigation.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { ENVIRONMENT_TOKEN, OrderViewModel } from 'libs/core/src/public-api';
import { forkJoin, tap } from 'rxjs';
import { IAccountItem } from '../../account-items/account-items.component';
import { CardViewModel } from 'libs/core/src/lib/model/view-model/account-items/card/card.view.model';
import { AccountItemsVoucherViewModel } from 'libs/core/src/lib/model/view-model/account-items/voucher/account-items-voucher.view.model';

@Component({
  template: '',
})
export class UserLoyaltyComponent implements OnInit {
  protected order: OrderViewModel;
  protected user: UserViewModel;
  protected accountItems: AccountItemsViewModel;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected userDataProvider: UserDataProvider,
    protected loadingService: LoadingService,
    protected authStateService: AuthStateService,
    protected voucherService: VoucherService,
    protected orderStateService: OrderStateService,
    protected router: Router,
    protected cardPaymentService: CardPaymentService,
    protected navigationService: NavigationService,
    protected loyaltyService: LoyaltyService
  ) {}

  ngOnInit() {
    this.authStateService.state$.subscribe((user) => {
      if (user?.token) {
        this.user = new UserViewModel(user?.user);

        this.userDataProvider
          .getAccountItems()
          .pipe(
            tap((response) => {
              this.accountItems = response;
            })
          )
          .subscribe({ complete: () => this.loadingService.hideLoader(LoaderEnum.MAIN) });
      }
    });

    this.orderStateService.state$.subscribe((order) => {
      this.order = order;
    });
  }

  useItem(item: IAccountItem) {
    if (!item) {
      console.error('function useItem need item!');
      return;
    }

    if (!item.model) {
      console.error('function useItem need item.model!');
      return;
    }

    const order = this.orderStateService.getOrder();
    switch (item.modelType) {
      case 'voucher':
        if (!order) {
          this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.VoucherWarning);
          return;
        }

        this.loadingService.showLoader(LoaderEnum.MAIN);
        this.useVoucher(item.model as AccountItemsVoucherViewModel, order);
        break;
      case 'card':
        if (!order) {
          this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.CardWarning);
          return;
        }

        this.loadingService.showLoader(LoaderEnum.MAIN);
        this.useCard(item.model as CardViewModel, order);
        break;
      default:
        break;
    }
  }

  useCard(card: CardViewModel, order: OrderViewModel) {
    if (!card) {
      console.error('function useCard need card!');
      return;
    }

    this.cardPaymentService.useCard(card.id, card.type).subscribe({
      next: (res) => {
        this.loadingService.hideLoader(LoaderEnum.MAIN);

        if (!res?.hasCardPayment(card?.id)) {
          this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.CardError);
          return;
        }

        this.router.navigate(['payment']);
      },
      error: (e) => {
        this.loadingService.hideLoader(LoaderEnum.MAIN);
        this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.CardError);
      },
    });
  }

  useVoucher(voucher: AccountItemsVoucherViewModel, order: OrderViewModel) {
    if (!voucher) {
      console.error('function useVoucher need voucher!');
      return;
    }
    if (!order) {
      console.error('function useVoucher need order!');
      return;
    }

    this.voucherService.assignVoucherToOrderAndSetOrder(order.cinemaId, order.id, voucher.number).subscribe({
      next: (res) => {
        this.loadingService.hideLoader(LoaderEnum.MAIN);
        this.router.navigate(['payment']);
      },
      error: (e) => {
        this.loadingService.hideLoader(LoaderEnum.MAIN);
        this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.VoucherError);
      },
    });
  }

  findLoyaltyCard() {
    return this.accountItems?.cards?.find((f) => this.cardPaymentService.isLoyalty(f.type));
  }

  back(): void {
    this.navigationService.back();
  }
}

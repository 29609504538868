import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { CreatePaymentRedirectUrl } from '../../../create-payment-redirect-url';
import { PaymentHelperService } from '../../../service/payment-helper.service';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { PaymentMethodEnum } from '../payu/payment-method.enum';
import { OrderPaymentPackage } from '../../../../../../../../core/src/lib/model/order-payment.package.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { OrderPaymentRequestModel } from 'libs/core/src/lib/model/request/order-payment.request.model';

@Component({
  selector: 'app-payment-provider-sandbox-component',
  templateUrl: './sandbox-payment-provider.component.html',
})
export class SandboxPaymentProviderComponent implements PaymentProviderComponentInterface, OnInit, OnDestroy {
  @Output() public events = new EventEmitter<PaymentProviderEvent>();

  public paymentMethod = PaymentMethodEnum;
  public readonly paymentProviderIdentifier: string = 'sandbox';
  public paymentChannel: string = null;

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private orderStateService: OrderStateService,
    private paymentHelperService: PaymentHelperService,
    private route: ActivatedRoute
  ) {}

  public ngOnDestroy(): void {}

  public ngOnInit(): void {
    this.paymentChannel = this.environment.constants.paymentChannel;
  }

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    setTimeout(() => {
      if (paymentModel.plainPayload) {
        window.location.href = paymentModel.plainPayload;
        return;
      }

      const request: CreatePaymentRedirectUrl = new CreatePaymentRedirectUrl();
      request.paymentChannel = this.paymentChannel;
      request.order = this.orderStateService.getOrder();
      request.route = this.route;

      window.location.href = this.paymentHelperService.createPaymentRedirectUrl(request);
    }, 2000);
  }

  public onPreInitPayment(event: OrderPaymentPackage): Observable<OrderPaymentPackage> {
    const requestModel = new OrderPaymentRequestModel();
    requestModel.intPayMethodType = 'user';
    requestModel.intPayMethodValue = '';

    return new Observable<OrderPaymentPackage>((subscriber) => {
      event.requestModel = requestModel;
      subscriber.next(event);
      subscriber.complete();
    });
  }
}

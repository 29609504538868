import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { UserLoginRequestModel } from '../model/request/user-login.request.model';
import { UserLoginResponseModel } from '../model/response/user-login.response.model';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { UserTokenRequestModel } from '../model/request/user-token.request.model';
import { UserTokenResponseModel } from '../model/response/user-token.response.model';
import { UserAuthRequestModel } from '../model/request/user-auth.request.model';
import { UserApiModel } from '../model/api-model/user/user.api.model';
import { UserDeleteRequestModel } from '../model/request/user-delete.request.model';
import { UserSetpasswordRequestModel } from '../model/request/user-setpassword.request.model';
import { UserHistoryApiModel } from '../model/api-model/user-history/user-history.api.model';
import { PickupTimeStatusResponseModel } from '../model/response/pickup-time-status.response.model';
import { AccountItemsModel } from '../model/api-model/user/user.response.model';
import { UserWatchlistApiModel } from '../model/api-model/user/user-watchlist.api.model';
import { ItemTransferRequestModel } from '../model/request/item-transfer.request.model';
import { UserWatchlistRequestModel } from '../model/api-model/user/user-watchlist.request.model';
import { UserFavotiePaymentApiModel } from '../model/api-model/user/user-favorite-payment.api.model';
import { UserPreferencesApiModel } from '../model/api-model/user/user-preferences.api.model';
import { UserPreferencesRequestModel } from '../model/request/user-preferences.request.model';
import { UserTwoFactorAuthenticationRequestModel } from '../model/request/user-2fa.request.model';
import { ProgramApiModel } from '../model/api-model/member-get-member/program.api.model';
import { PromotionConditionApiModel } from '../model/api-model/member-get-member/promotion-condition.api.model';
import { UserReferralRecomendationdRequestModel } from '../model/request/user-referral-recommendation.request.model';
import { UserDeletionReasonApiModel } from '../model/api-model/user/user-deletion-reason.api.model';
import { CinemaApiModel } from '../model/api-model/cinema/cinema.api.model';
import { GenreApiModel } from '../model/api-model/genre/genre.api.model';
import { AccountItemsApiModel } from '../model/api-model/account-items/account-items.api.model';
import { UserHistoryRequestModel } from '../model/request/user-history.request.model';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {
  public static cacheBuster$ = new Subject<void>();
  public static cacheModifier$ = new Subject<any>();

  constructor(private http: HttpClient) {}

  public login(request: UserLoginRequestModel): Observable<UserLoginResponseModel> {
    return this.http
      .post('/user/login', instanceToPlain(request, { groups: ['login'] }))
      .pipe(map((result) => plainToInstance(UserLoginResponseModel, result as Object)));
  }

  public loginByToken(request: UserTokenRequestModel): Observable<UserTokenResponseModel> {
    return this.http.post('/user/token', instanceToPlain(request)).pipe(map((result) => plainToInstance(UserTokenResponseModel, result as Object)));
  }

  public doAuth(authProvider: string, request: UserAuthRequestModel): Observable<UserLoginResponseModel> {
    return this.http
      .post('/user/auth/' + authProvider, instanceToPlain(request))
      .pipe(map((result) => plainToInstance(UserLoginResponseModel, result as Object)));
  }

  public doAuthByToken(authProvider: string, token: string, state: string = null): Observable<UserLoginResponseModel> {
    const provider = authProvider.toLowerCase();
    const body = {
      token: token,
      state: state,
    };

    return this.http.post('/user/auth/' + provider, body).pipe(map((result) => plainToInstance(UserLoginResponseModel, result as Object)));
  }

  public getAuthRedirect(authProvider: string, redirectUrl: string): Observable<string> {
    const options = {};
    if (redirectUrl) {
      options['params'] = {};
      options['params']['redirectUrl'] = redirectUrl;
    }

    return this.http.get('/user/auth/' + authProvider, options).pipe(map((result) => result['redirectUrl']));
  }

  public update(request: UserApiModel, params?): Observable<UserApiModel> {
    const u = instanceToPlain(request);

    return this.http.put(`/user`, instanceToPlain(request), params).pipe(map((result) => plainToInstance(UserApiModel, result as Object)));
  }

  public create(request: UserApiModel, params?): Observable<UserApiModel> {
    return this.http.post(`/user`, instanceToPlain(request), params).pipe(map((result) => plainToInstance(UserApiModel, result as Object)));
  }

  public setEmail(email: string): Observable<any> {
    return this.http.put(`/user/email`, { email: email });
  }

  public remindPassword(email: string): Observable<any> {
    return this.http.post(`/user/passwordtoken`, { email: email });
  }

  public remove(requestModel: UserDeleteRequestModel): Observable<any> {
    return this.http.request('DELETE', `/user`, {
      body: {
        password: requestModel.password,
        reasonId: requestModel.reasonId,
      },
    });
  }

  public confirm(registrationTokenId: string): Observable<any> {
    return this.http.get(`/user/confirm?registrationTokenId=${registrationTokenId}`);
  }

  public setPassword(request: UserSetpasswordRequestModel): Observable<any> {
    return this.http.put(`/user/password`, instanceToPlain(request));
  }

  public getCurrent(token?: string): Observable<UserApiModel> {
    const params = token
      ? {
          headers: new HttpHeaders({
            Authorization: `Bearer ${token}`,
          }),
        }
      : null;

    return this.http.get<UserApiModel>('/user', params);
  }

  public getHistoryViaApiModel(request?: UserHistoryRequestModel): Observable<UserHistoryApiModel> {
    return this.http.get('/user/history', { params: instanceToPlain(request) }).pipe(map((result) => plainToInstance(UserHistoryApiModel, result as Object)));
  }

  public authMethod(): Observable<string[]> {
    return this.http.get('/user/auth/method').pipe(map((result) => result as string[]));
  }

  public deleteAuthMethod(method: string): Observable<Object> {
    return this.http.delete('/user/auth/method', {
      body: {
        authMethod: method,
      },
    });
  }

  public pickupTimeStatus(cinemaId: string, orderId: string): Observable<PickupTimeStatusResponseModel> {
    return this.http
      .get(`/cinema/${cinemaId}/order/${orderId}/fb/pickupStatus`)
      .pipe(map((result) => plainToInstance(PickupTimeStatusResponseModel, result as Object)));
  }

  public prepareFood(cinemaId: string, orderId: string): Observable<any> {
    return this.http.put(`/cinema/${cinemaId}/order/${orderId}/fb/prepare`, null);
  }

  public getFavouriteCinemaList(): Observable<Array<CinemaApiModel>> {
    return this.http.get<CinemaApiModel[]>('/user/cinema');
  }

  public setFavoriteCinemaList(request: Array<string>): Observable<any> {
    return this.http.patch('/user/cinema', instanceToPlain(request));
  }

  public getFavouriteGenreList(): Observable<Array<GenreApiModel>> {
    return this.http.get<GenreApiModel[]>('/user/genre');
  }

  public setFavoriteGenreList(request: Array<string>): Observable<any> {
    return this.http.patch('/user/genre', instanceToPlain(request));
  }

  public setUserAvatar(avatarFile: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('payload', avatarFile, avatarFile.name);

    return this.http.post<void>(`/user/avatar`, formData);
  }

  @Cacheable({
    cacheBusterObserver: UserHttpService.cacheBuster$,
    storageStrategy: LocalStorageStrategy,
    maxAge: 15000,
  })
  public getAccountItems(): Observable<AccountItemsApiModel> {
    return this.http.get<AccountItemsApiModel>('/user/accountItems').pipe(
      map((result) => {
        return plainToInstance(AccountItemsApiModel, result as object);
      })
    );
  }

  @Cacheable({
    cacheModifier: UserHttpService.cacheModifier$,
    maxAge: 3600000,
  })
  public getWatchlist(): Observable<UserWatchlistApiModel> {
    return this.http.get('/user/movie').pipe(map((result) => plainToInstance(UserWatchlistApiModel, result as Object)));
  }

  public patchWatchlist(requestModel: UserWatchlistRequestModel): Observable<UserWatchlistApiModel> {
    return this.http.patch<UserWatchlistApiModel>(`/user/movie`, requestModel).pipe(
      map((result) => plainToInstance(UserWatchlistApiModel, result as Object)),
      tap((res) => UserHttpService.cacheModify(res))
    );
  }

  public transferItem(request: ItemTransferRequestModel): Observable<Object> {
    return this.http.post('/user/transferItem', instanceToPlain(request));
  }

  private static cacheModify(responseData: Object): void {
    UserHttpService.cacheModifier$.next((data: any[]) => {
      const oldCacheRow = data[0];
      if (!oldCacheRow) {
        return;
      }

      Object.assign(oldCacheRow.response, {
        ...responseData,
      });

      return data;
    });
  }

  public getFavotiePayment(): Observable<UserFavotiePaymentApiModel[]> {
    return this.http
      .get<UserFavotiePaymentApiModel[]>('/user/paymenttoken')
      .pipe(map((res) => plainToInstance(UserFavotiePaymentApiModel, res as Object[], { strategy: 'excludeAll' })));
  }

  public deletePaymentToken(providerId: string, token: string): Observable<any> {
    return this.http.request('DELETE', '/user/paymenttoken', {
      body: {
        providerId: providerId,
        token: token,
      },
    });
  }

  public getPreferences(): Observable<UserPreferencesApiModel> {
    return this.http
      .get<UserPreferencesApiModel>('/user/preferences')
      .pipe(map((res) => plainToInstance(UserPreferencesApiModel, res as Object, { strategy: 'excludeAll' })));
  }

  public putPreferences(request: UserPreferencesRequestModel) {
    return this.http.put(`/user/preferences`, request);
  }

  public createTwoFactorAuthenticationToken(request: UserTwoFactorAuthenticationRequestModel) {
    return this.http.post('/user/2fa', instanceToPlain(request));
  }

  // Assigns loyalty item to the account
  public assignLoyaltyItem(request) {
    return this.http.put(`/user/loyaltyItem`, request);
  }

  public getMemberGetMemberReferralPrograms(): Observable<ProgramApiModel[]> {
    return this.http.get('/user/referral/programs').pipe(map((res) => plainToInstance(ProgramApiModel, res as Object[], { strategy: 'excludeAll' })));
  }

  public listMemberGetMemberReferralRedeemable(promotionId: string): Observable<PromotionConditionApiModel> {
    return this.http.get(`/user/referral/redeemable/${promotionId}`).pipe(map((result) => plainToInstance(PromotionConditionApiModel, result as Object)));
  }

  public setMemberGetMemberReferralRecomendation(request: UserReferralRecomendationdRequestModel) {
    return this.http.post('/user/referral/recommendation', instanceToPlain(request));
  }

  @Cacheable({
    cacheModifier: UserHttpService.cacheModifier$,
    maxAge: 3600000,
  })
  public getDeletionReason(): Observable<UserDeletionReasonApiModel[]> {
    return this.http.get('/user/deletionReason').pipe(map((result) => plainToInstance(UserDeletionReasonApiModel, result as Object[])));
  }
}

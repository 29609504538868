import { Injectable } from '@angular/core';
import { forkJoin, from, iif, Observable, of } from 'rxjs';
import { OrderHttpService } from '../http/order.http.service';
import { flatMap, last, map, mergeAll, mergeMap, reduce, switchMap, tap } from 'rxjs/operators';
import cloneDeep from 'lodash-es/cloneDeep';
import { OrderItemHttpService } from '../http/order-item.http.service';
import { OrderVoucherItemHttpService } from '../http/order-voucher-item.http.service';
import { OrderCateringItemHttpService } from '../http/order-catering-item.http.service';
import { OrderRewardsHttpService } from '../http/order-rewards.http.service';
import { TicketHttpService } from '../http/order-ticket.http.service';
import { CardHttpService } from '../http/card.http.service';
import { VoucherHttpService } from '../http/voucher.http.service';
import { OrderViewModel } from '../model/view-model/order/order.view.model';
import { OrderApiModel } from '../model/api-model/order/order.api.model';
import { OrderAliasViewModel } from '../model/view-model/order/order-alias.view.model';
import { VoucherItemViewModel } from '../model/view-model/order/voucher-item/voucher-item.view.model';
import { FbItemViewModel } from '../model/view-model/order/fb-item/fb-item.view.model';
import { ScreeningItemViewModel } from '../model/view-model/order/screening-item/screening-item.view.model';
import { OrderPaymentRequestModel } from '../model/request/order-payment.request.model';
import { OrderMembershipUpdateRequestModel } from '../model/request/order-membership-update.request.model';
import { FlowType } from '../model/component/steps/flow-type.model';
import { TicketViewModel } from '../model/view-model/shared/ticket/ticket.view.model';
import { CmsHelper } from '../helper/cms.helper';
import { TicketApiModel } from '../model/api-model/shared/ticket/ticket.api.model';
import { PaymentProviderConfigRequestModel } from '../model/request/payment-provider-config.request.model';
import { PaymentConfigViewModel } from '../model/view-model/payment/config/payment.config.view.model';
import { PaymentProviderPayMethodRequestModel } from '../model/request/payment-provider-pay-method.request.model';
import { VirtualPassViewModel } from '../model/view-model/sales-document/virtual-pass/virtual-pass.view.model';
import { VirtualPassApiModel } from '../model/api-model/sales-document/virtual-pass/virtual-pass.api.model';
import { BonusItemModel } from '../model/api-model/reward/reward.request.model';
import { UseCardRequestModel } from '../model/request/use-card.request.model';
import { PurchaseCardTypeAddressRequestModel } from '../model/request/purchase-card-type-address.request.model';
import { PurchaseCardTypeRequestModel } from '../model/request/purchase-card-type.request.model';
import { CardItemViewModel } from '../model/view-model/order/card-item/card-item.view.model';
import { OrderAliasApiModel } from '../model/api-model/order/order-alias.api.model';
import { PaymentProviderPayMethodViewModel } from '../model/view-model/payment-provider-pay-method.view.model';
import { PaymentProviderPayMethodApiModel } from '../model/api-model/payment-provider-pay-method.response.model';
import { PaymentMethodApiModel } from '../model/api-model/order/payment-method/payment-method.api.model';
import { PaymentMethodViewModel } from '../model/view-model/order/payment-method/payment-method.view.model';
import { PaymentViewModel } from '../model/view-model/payment.view.model';
import { PickupTimeViewModel } from '../model/pickup-time.model';
import { PickupTimeApiModel } from '../model/api-model/pickup-time.api.model';
import { CateringViewModel } from '../model/view-model/order/catering.view.model';
import { CateringApiModel } from '../model/api-model/order/catering.api.model';
import { CardTypeItemViewModel } from '../model/view-model/order/card-type-item/card-type-item.view.model';
import { WordpressDataProvider } from './wordpress.data-provider';
import { SeatRequestRequestModel } from '../model/api-model/seat/seat.request.model';
import { storageKey } from '../../app.const';
import { CateringModel } from '../model/catering/catering.model';
import { DateTime } from 'luxon';
import { UserApiModel } from '../model/api-model/user/user.api.model';
import { RecaptchaService } from '../service/recaptcha/recaptcha.service';
import { ExecutionStrategy } from '../enum/execution-strategy.enum';
import { IOrderPaymentPackage } from '../model/interface/order-payment.package.model';

@Injectable({
  providedIn: 'root',
})
export class OrderDataProvider {
  constructor(
    private httpService: OrderHttpService,
    private itemsHttpService: OrderItemHttpService,
    private voucherItemsHttpService: OrderVoucherItemHttpService,
    private cateringItemsHttpService: OrderCateringItemHttpService,
    private orderRewardsHttpService: OrderRewardsHttpService,
    private ticketHttpService: TicketHttpService,
    private cardHttpService: CardHttpService,
    private voucherHttpService: VoucherHttpService,
    private wordpressDataProvider: WordpressDataProvider,
    private cmsHelper: CmsHelper,
    private recaptchaService: RecaptchaService
  ) {}

  getOrder(cinemaId: string, orderId: string, executionStrategy: ExecutionStrategy = ExecutionStrategy.NORMAL): Observable<OrderViewModel> {
    if (!orderId) {
      return of(null);
    }

    return this.httpService.getOrder(cinemaId, orderId, executionStrategy).pipe(map((order) => new OrderViewModel(cinemaId, order)));
  }

  closeBasket(cinemaId: string, orderId: string, paymentConfirmed: boolean = true) {
    return this.httpService.closeBasket(cinemaId, orderId, paymentConfirmed);
  }

  getOrderTransactionNumber(bookingId: string): Observable<OrderAliasApiModel> {
    return this.httpService.getOrderTransactionNumber(bookingId).pipe(map((order) => new OrderAliasViewModel(order)));
  }

  create(cinemaId: string, sourceOrderId: string = null): Observable<OrderApiModel> {
    return this.httpService.create(cinemaId, sourceOrderId);
  }

  update(cinemaId: string, order: OrderApiModel): Observable<OrderViewModel> {
    return this.httpService.update(cinemaId, order).pipe(map((order) => new OrderViewModel(cinemaId, order)));
  }

  updateOrderUserData(cinemaId: string, order: OrderViewModel, user: UserApiModel) {
    order.userFirstName = user.firstName;
    order.userLastName = user.lastName;
    order.userPhone = user.phone;
    order.userEmail = user.email;

    return this.update(cinemaId, order.toApiModel());
  }

  delete(cinemaId: string, orderId: string): Observable<boolean> {
    return this.httpService.delete(cinemaId, orderId).pipe(map(() => true));
  }

  silentDelete(order: OrderViewModel): boolean {
    return this.httpService.silentDelete(order.cinemaId, order.id);
  }

  put(cinemaId: string, order: OrderApiModel): Observable<OrderViewModel> {
    if (order.id === null) {
      return this.create(cinemaId)
        .pipe(
          map((o: OrderApiModel) => {
            order.id = o.id;
            order.status = o.status;
            order.dateEntry = o.dateEntry;

            return this.update(cinemaId, order);
          })
        )
        .pipe((x) => x.pipe(flatMap((y) => y)));
    } else {
      return this.update(cinemaId, order);
    }
  }

  patchVoucherItems(cinemaId: string, items: Array<VoucherItemViewModel>, order: OrderViewModel | null = null): Observable<OrderViewModel> {
    items = this.aggregateOrderVoucherItemCollection(items);

    if (order === null) {
      return this.create(cinemaId)
        .pipe(
          flatMap((o) => {
            return this.voucherItemsHttpService
              .patch(
                cinemaId,
                o.id,
                items.map((item) => item.toApiModel())
              )
              .pipe(
                map((resItems) => {
                  o.voucherItems = resItems.map((resItem) => new VoucherItemViewModel(resItem));

                  return o;
                })
              );
          })
        )
        .pipe(map((x) => new OrderViewModel(cinemaId, x)));
    }

    return this.voucherItemsHttpService.patch(cinemaId, order.id, items).pipe(
      map((resItems) => {
        order.voucherItems = resItems.map((resItem) => new VoucherItemViewModel(resItem));

        return order;
      })
    );
  }

  plainPatchVoucherItems(cinemaId: string, orderId: string, itemId: string, quantity: number = 1): Observable<OrderViewModel> {
    return this.voucherItemsHttpService.plainPatch(cinemaId, orderId, itemId, quantity).pipe(map((res) => new OrderViewModel(cinemaId, res)));
  }

  deleteVoucherItem(cinemaId: string, orderId: string, itemId: string, voucherNumber: string) {
    return this.voucherItemsHttpService.delete(cinemaId, orderId, itemId, voucherNumber).pipe(map((res) => new OrderViewModel(cinemaId, res)));
  }

  tryPatchOrderFbItemSeat(cinemaId: string, orderId: string, res: OrderViewModel, seatContext: any) {
    if (!seatContext) {
      return of(res);
    }

    const orderItemId = res.fbItems.find((item) => item.articleId === item.articleId)?.id;
    if (!orderItemId) {
      return of(res);
    }

    let request = { orderItemId: orderItemId } as SeatRequestRequestModel;
    if (seatContext) {
      request = Object.assign({ orderItemId: orderItemId }, seatContext);
    }

    return this.patchOrderFbItemSeat(cinemaId, orderId, [request]).pipe(map(() => res));
  }

  /**
   * Patches catering items into order
   */
  patchCateringItems(cinemaId: string, orderId: string, items: Array<FbItemViewModel>, seatContext?: any): Observable<OrderViewModel> {
    return iif(() => !orderId, this.create(cinemaId).pipe(map((order) => order.id)), of(orderId)).pipe(
      switchMap((orderId) =>
        this.cateringItemsHttpService
          .put(
            cinemaId,
            orderId,
            items.map((item) => item.toApiModel())
          )
          .pipe(map((res) => new OrderViewModel(cinemaId, res)))
      ),
      switchMap((res) => this.tryPatchOrderFbItemSeat(cinemaId, orderId, res, seatContext))
    );
  }

  /**
   * Post catering item into order
   */
  postCateringItem(cinemaId: string, orderId: string, item: FbItemViewModel, seatContext?: any): Observable<OrderViewModel> {
    return this.cateringItemsHttpService.post(cinemaId, orderId, item.toApiModel()).pipe(
      map((res) => new OrderViewModel(cinemaId, res)),
      switchMap((res) => this.tryPatchOrderFbItemSeat(cinemaId, orderId, res, seatContext))
    );
  }

  /**
   * Patch catering item into order
   */
  patchCateringItem(cinemaId: string, orderId: string, basketItemId: string, item: FbItemViewModel): Observable<OrderViewModel> {
    return this.cateringItemsHttpService.patchItem(cinemaId, orderId, basketItemId, item.toApiModel()).pipe(map((res) => new OrderViewModel(cinemaId, res)));
  }

  patchQuantityCateringItem(cinemaId: string, orderId: string, basketItemId: string, quantity: number): Observable<OrderViewModel> {
    return this.cateringItemsHttpService.patchQuantityItem(cinemaId, orderId, basketItemId, quantity).pipe(map((res) => new OrderViewModel(cinemaId, res)));
  }

  deleteCateringItem(cinemaId: string, orderId: string, itemId: string) {
    return this.cateringItemsHttpService.delete(cinemaId, orderId, itemId).pipe(map((res) => new OrderViewModel(cinemaId, res)));
  }

  patchItems(cinemaId: string, order: OrderViewModel = null, items: ScreeningItemViewModel[], generalAdmission: boolean = false): Observable<OrderViewModel> {
    if (order === null) {
      const itemList: Array<ScreeningItemViewModel> = new Array<ScreeningItemViewModel>();

      if (generalAdmission === true) {
        items.forEach((x) => {
          const cloned = cloneDeep(x);
          cloned.seatId = null;
          cloned.quantity = 1;
          itemList.push(cloned);
        });

        items = itemList;
      }
    }

    return iif(() => order === null, this.create(cinemaId), of(order)).pipe(
      switchMap((order: OrderViewModel) =>
        this.itemsHttpService
          .patch(
            cinemaId,
            order.id,
            items.map((item) => item.toApiModel())
          )
          .pipe(map((orderResponse) => new OrderViewModel(cinemaId, orderResponse)))
      )
    );
  }

  deleteItem(cinemaId: string, orderId: string, screeningItemId: string) {
    return this.itemsHttpService.delete(cinemaId, orderId, screeningItemId).pipe(map((order) => new OrderViewModel(cinemaId, order)));
  }

  updateAgreements(cinemaId: string, orderId: string, agreements: string[]) {
    return this.httpService.updateAgreement(cinemaId, orderId, agreements);
  }

  deletePayment(cinemaId: string, orderId: string, provider: string) {
    return this.httpService.removeExternalPaymentMethod(new PaymentProviderPayMethodRequestModel(cinemaId, orderId, provider, 'web')).pipe(map(() => true));
  }

  postPayment(orderPaymentPackage: IOrderPaymentPackage) {
    return this.recaptchaService.getRecaptchaToken('start_transaction').pipe(
      switchMap((token) => {
        orderPaymentPackage.recaptchaResponse = token;
        return this.httpService.postPayment(orderPaymentPackage).pipe(map((response) => new PaymentViewModel(response)));
      })
    );
  }

  membershipUpdate(cinemaId: string, orderId: string, request: OrderMembershipUpdateRequestModel): Observable<OrderViewModel> {
    return this.httpService.membershipUpdate(cinemaId, orderId, request).pipe(
      mergeMap(() => this.getOrder(cinemaId, orderId)) // TODO: Remove when API returns in response
    );
  }

  getPaymentMethodList(cinemaId: string, orderId: string) {
    return this.httpService.getPaymentMethodList(cinemaId, orderId).pipe(
      map((paymentMethodResponseModels: Array<PaymentMethodApiModel>) => {
        return paymentMethodResponseModels.map((paymentMethodResponseModel) => new PaymentMethodViewModel(paymentMethodResponseModel));
      })
    );
  }

  verify(cinemaId: string, orderId: string) {
    return this.httpService.getVerify(cinemaId, orderId);
  }

  getTickets(cinemaId: string, orderId: string, flowType: FlowType = FlowType.Standard): Observable<TicketViewModel[]> {
    const ticketsSource = this.ticketHttpService
      .getOrderTickets(cinemaId, orderId)
      .pipe(map((ticketApiModels: TicketApiModel[]) => ticketApiModels.map((ticketApiModel) => new TicketViewModel(ticketApiModel))));

    return iif(
      () => this.cmsHelper.canUseCms,
      forkJoin({
        tickets: ticketsSource,
        packages: this.wordpressDataProvider.getPackages(),
      }).pipe(
        map((data) => {
          if (flowType === FlowType.Birthday) {
            return data.tickets
              .filter((t) => data.packages.some((p) => p.ticketType === t.id))
              .map((ticket) => {
                const packageTicket = data.packages.find((p) => p.ticketType === ticket.id);
                return Object.assign(new TicketViewModel(ticket), {
                  minimalTicketQuantity: packageTicket.minimalTicketQuantity,
                  maximumTicketQuantity: packageTicket.maximumTicketQuantity,
                  price: packageTicket.price,
                });
              });
          }

          return data.tickets.filter((t) => data.packages.every((p) => p.ticketType !== t.id)).map((ticket) => new TicketViewModel(ticket));
        })
      ),
      ticketsSource
    );
  }

  getPaymentProviderConfig(request: PaymentProviderConfigRequestModel): Observable<PaymentConfigViewModel> {
    return this.httpService.getPaymentProviderConfig(request).pipe(map((x) => new PaymentConfigViewModel(x)));
  }

  getPaymentProviderPayMethodCollection(request: PaymentProviderPayMethodRequestModel): Observable<Array<PaymentProviderPayMethodViewModel>> {
    return this.httpService
      .getPaymentProviderPayMethodCollection(request)
      .pipe(map((models: PaymentProviderPayMethodApiModel[]) => models.map((model) => new PaymentProviderPayMethodViewModel(model))));
  }

  getVirtualPass(
    cinemaId: string,
    orderId: string,
    virtualPassProvider: string,
    screeningId: string | undefined,
    mode: 'reservation' | 'ticket' | 'alltickets' | undefined
  ): Observable<VirtualPassViewModel[]> {
    return this.httpService
      .getVirtualPass(cinemaId, orderId, virtualPassProvider, screeningId, mode)
      .pipe(map((models: VirtualPassApiModel[]) => models.map((model) => new VirtualPassViewModel(model))));
  }

  /**
   * Gets available catering for order
   */
  getCatering(cinemaId: string, orderId: string = null, screenGroupId: string = null): Observable<CateringViewModel> {
    return this.httpService.getCatering(cinemaId, orderId, screenGroupId).pipe(map((model: CateringApiModel) => new CateringViewModel(model)));
  }

  public getPickupTime(cinemaId: string): Observable<PickupTimeViewModel[]> {
    return this.httpService.getPickupTime(cinemaId).pipe(map((models: PickupTimeApiModel[]) => models.map((model) => new PickupTimeViewModel(model))));
  }

  public patchPickupTime(pickupTimeId: string, orderId: string, cinemaId: string): Observable<boolean> {
    return this.httpService.patchPickupTime(pickupTimeId, orderId, cinemaId);
  }

  public putPickupTime(cinemaId: string, orderId: string, pickupTime: DateTime) {
    return this.httpService.putPickupTime(cinemaId, orderId, pickupTime).pipe(mergeMap(() => this.getOrder(cinemaId, orderId)));
  }

  public removeItem(cinemaId: string, orderId: string, itemId: string): Observable<OrderViewModel> {
    return this.httpService.removeItem(cinemaId, orderId, itemId).pipe(map((order) => new OrderViewModel(cinemaId, order)));
  }

  public removeScreening(order: OrderViewModel, cinemaId: string, screeningId: string, generalAdmission: boolean = false): Observable<OrderViewModel> {
    let targetOrderItems: Array<ScreeningItemViewModel> = cloneDeep<Array<ScreeningItemViewModel>>(order.screeningItems);
    targetOrderItems = targetOrderItems.filter((x) => x.screeningId !== screeningId);

    const clonedOrder: OrderViewModel = cloneDeep<OrderViewModel>(order);
    clonedOrder.screeningItems = targetOrderItems;

    return this.patchItems(cinemaId, clonedOrder, [], generalAdmission);
  }

  private aggregateOrderVoucherItemCollection(voucherItemCollection: Array<VoucherItemViewModel>): Array<VoucherItemViewModel> {
    const aggregationMap: Map<string, VoucherItemViewModel> = new Map<string, VoucherItemViewModel>();

    voucherItemCollection.forEach((voucherItem) => {
      const aggregationKey: string = voucherItem.itemId;

      if (aggregationMap.has(aggregationKey)) {
        aggregationMap.get(aggregationKey).quantity++;
      } else {
        const orderVoucherItem = cloneDeep(voucherItem);
        orderVoucherItem.quantity = 1;

        aggregationMap.set(aggregationKey, orderVoucherItem);
      }
    });

    return Array.from<VoucherItemViewModel>(aggregationMap.values());
  }

  getCardBonuses(cinemaId: string, cardId: string) {
    return this.orderRewardsHttpService.getCardBonuses(cinemaId, cardId);
  }

  patchBonusItem(cinemaId: string, orderId: string, item: BonusItemModel) {
    return this.orderRewardsHttpService.patch(cinemaId, orderId, item).pipe(mergeMap(() => this.getOrder(cinemaId, orderId)));
  }

  public convertOrderBetweenCinemas(order: OrderViewModel, newCinemaId: string): Observable<OrderViewModel> {
    let convertedOrderId;
    const redemptionPointsFlowConvert$ = () => {
      return this.cardHttpService
        .assignCardToOrder(newCinemaId, convertedOrderId, order.cardId)
        .pipe(
          mergeMap(() =>
            this.convertBonusItems(
              [
                ...order.voucherItems.filter((item) => item.itemRedemptionPoints !== null),
                ...order.cardTypeItems.filter((item) => item.itemRedemptionPoints !== null),
              ],
              newCinemaId,
              convertedOrderId
            )
          )
        );
    };

    const transactionFlowConvert$ = () => {
      return this.convertVoucherItems(order, newCinemaId, convertedOrderId).pipe(
        mergeMap(() => this.convertCardTypeItems(order, newCinemaId, convertedOrderId))
      );
    };

    return this.create(newCinemaId).pipe(
      tap((convertedOrder) => {
        convertedOrderId = convertedOrder.id;
      }),
      mergeMap(() => this.convertCardItems(order, newCinemaId, convertedOrderId)), // TODO: Check if SOAP deal with multiple async requests
      mergeMap(() => iif(() => order.isRewardsTransaction === true, redemptionPointsFlowConvert$(), transactionFlowConvert$())),
      last(),
      mergeMap(() => this.delete(order.cinemaId, order.id)),
      mergeMap(() => {
        const vouchers = order.getVouchers();
        if (vouchers.length > 0) {
          return from(vouchers).pipe(
            mergeMap((voucher) => this.voucherHttpService.putVoucherToOrder(newCinemaId, convertedOrderId, { voucherNumber: voucher.number })),
            last()
          );
        }

        return of(null);
      }),
      mergeMap(() => {
        const giftCards = order.paymentMethods
          .filter((paymentMethod) => paymentMethod.cardId)
          .map((paymentMethod) => ({ cardId: paymentMethod.cardId } as UseCardRequestModel));
        return giftCards.length > 0 ? this.cardHttpService.useCard(newCinemaId, convertedOrderId, giftCards) : of(null);
      }),
      switchMap(() => this.getOrder(newCinemaId, convertedOrderId))
    );
  }

  private convertCardItems(order: OrderViewModel, newCinemaId: string, newOrderId: string): Observable<any> {
    return order.cardItems.length > 0
      ? from(order.cardItems).pipe(
          reduce((prev, curr) => {
            const record = prev.find((x) => x.cardBatchId === curr.cardBatchId && x.value === curr.value);

            if (record) {
              record.quantity += 1;
            } else {
              prev.push(curr);
            }

            return prev;
          }, []),
          mergeAll(),
          mergeMap((cardItem: CardItemViewModel) =>
            of({
              batchId: cardItem.cardBatchId,
              cardTypeId: cardItem.cardTypeId,
              chargeValue: cardItem.value,
              quantity: cardItem.quantity,
              email: order.userEmail,
              shippingAddress: cardItem.shippingAddress
                ? ({
                    address1: cardItem.shippingAddress.address1,
                    address2: cardItem.shippingAddress.address2,
                    city: cardItem.shippingAddress.city,
                    firstName: cardItem.shippingAddress.firstName,
                    lastName: cardItem.shippingAddress.lastName,
                    postalCode: cardItem.shippingAddress.postalCode,
                    street: cardItem.shippingAddress.street,
                    streetNumber: cardItem.shippingAddress.streetNumber,
                  } as PurchaseCardTypeAddressRequestModel)
                : null,
            } as PurchaseCardTypeRequestModel)
          ),
          mergeMap((request) => this.cardHttpService.addCardToOrder(newCinemaId, newOrderId, request)),
          last()
        )
      : of(null);
  }

  private convertVoucherItems(order: OrderViewModel, newCinemaId: string, newOrderId: string): Observable<any> {
    return order.voucherItems.length > 0
      ? from(order.voucherItems).pipe(
          mergeMap((voucherItem) => this.plainPatchVoucherItems(newCinemaId, newOrderId, voucherItem.itemId, voucherItem.quantity)),
          last()
        )
      : of(null);
  }

  private convertCardTypeItems(order: OrderViewModel, newCinemaId: string, newOrderId: string): Observable<any> {
    return order.cardTypeItems.length > 0
      ? from(order.cardTypeItems).pipe(
          mergeMap((cardItem) =>
            this.httpService.membershipUpdate(newCinemaId, newOrderId, new OrderMembershipUpdateRequestModel(cardItem.cardId, cardItem.id))
          ), // TODO: Add correct cardNumber and feeId
          last()
        )
      : of(null);
  }

  private convertBonusItems(bonusItems: (VoucherItemViewModel | CardTypeItemViewModel)[], newCinemaId, newOrderId) {
    return bonusItems.length
      ? from(bonusItems).pipe(
          mergeMap((item) =>
            this.patchBonusItem(newCinemaId, newOrderId, {
              articleId: item.itemId,
              quantity: item.quantity,
              delay: 0,
              delayType: 0,
            } as BonusItemModel)
          ),
          last()
        )
      : of(null);
  }

  public removeExternalPaymentMethod(request: PaymentProviderPayMethodRequestModel): Observable<any> {
    return this.httpService.removeExternalPaymentMethod(request);
  }

  addVoucherToOrder(cinemaId: string, orderId: string, itemId: string, quantity: number): Observable<OrderViewModel> {
    return this.httpService.addVoucherToOrder(cinemaId, orderId, itemId, quantity).pipe(map((order: OrderApiModel) => new OrderViewModel(cinemaId, order)));
  }

  putTable(cinemaId: string, orderId: string, tableId: string) {
    return this.httpService.putTable(cinemaId, orderId, tableId).pipe(
      tap((table) => {
        sessionStorage.setItem(storageKey.table, JSON.stringify(table));
      })
    );
  }

  patchOrderFbItemSeat(cinemaId: string, orderId: string, body: SeatRequestRequestModel[]) {
    return this.httpService.patchOrderFbItemSeat(cinemaId, orderId, body);
  }

  /**
   * Gets available catering for order
   */
  getOrderFb(cinemaId: string, orderId: string = null, screenGroupId: string = null): Observable<CateringModel> {
    return forkJoin({
      nutritionals: this.httpService.getFbNutritionalInfo(cinemaId),
      catering: this.httpService.getOrderFb(cinemaId, orderId, screenGroupId),
    }).pipe(map((x) => new CateringModel(x.catering, x.nutritionals)));
  }

  getApplePaySession(cinemaId: string, orderId: string, paymentProviderIdentifier: string): Observable<any> {
    return this.httpService.getApplePaySession(cinemaId, orderId, paymentProviderIdentifier);
  }
}

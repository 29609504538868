import { IOrderPaymentPackage } from './interface/order-payment.package.model';
import { OrderPaymentRequestModel } from './request/order-payment.request.model';

export class OrderPaymentPackage implements IOrderPaymentPackage {
  public cinemaId: string;
  public orderId: string;

  public paymentProviderIdentifier: string | null = null;

  public recaptchaResponse: string | null;

  public abort = false;

  constructor(public requestModel: OrderPaymentRequestModel = new OrderPaymentRequestModel()) {}

  get channel() {
    return this.requestModel.channel;
  }

  set channel(value: string) {
    this.requestModel.channel = value;
  }

  get continueUrl() {
    return this.requestModel.continueUrl;
  }

  set continueUrl(value: string) {
    this.requestModel.continueUrl = value;
  }

  get intPayMethodType() {
    return this.requestModel.intPayMethodType;
  }

  set intPayMethodType(value: string) {
    this.requestModel.intPayMethodType = value;
  }

  get intPayMethodValue() {
    return this.requestModel.intPayMethodValue;
  }

  set intPayMethodValue(value: string) {
    this.requestModel.intPayMethodValue = value;
  }

  get saveToken() {
    return this.requestModel.saveToken;
  }

  set saveToken(value: boolean) {
    this.requestModel.saveToken = value;
  }

  get paymentToken() {
    return this.requestModel.paymentToken;
  }

  set paymentToken(value: string) {
    this.requestModel.paymentToken = value;
  }

  get expiry() {
    return this.requestModel.expiry;
  }

  set expiry(value: string) {
    this.requestModel.expiry = value;
  }
}

import { Inject, Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import groupBy from 'lodash-es/groupBy';
import { DateTime } from 'luxon';
import { KeyValue } from 'libs/core/src/lib/helper/key-value';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/lib/injection.tokens';
import { IScreeningDetails } from 'libs/core/src/lib/interfaces';
import { Adapter, CustomOptions } from 'libs/core/src/lib/model/adapter';
import { CinemaApiModel } from 'libs/core/src/lib/model/api-model/cinema/cinema.api.model';
import { MovieApiModel } from 'libs/core/src/lib/model/api-model/movie/movie.api.model';
import { TrailerApiModel } from 'libs/core/src/lib/model/api-model/movie/trailer.api.model';
import { RegionApiModel } from 'libs/core/src/lib/model/api-model/region/region.api.model';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { EventViewModel } from 'libs/core/src/lib/model/view-model/event/event.view.model';
import { MoviePrintViewModel } from 'libs/core/src/lib/model/view-model/movie/movie-print.view.model';
import { MovieViewModel } from 'libs/core/src/lib/model/view-model/movie/movie.view.model';
import { TrailerViewModel } from 'libs/core/src/lib/model/view-model/movie/trailer.view.model';
import { RegionViewModel } from 'libs/core/src/lib/model/view-model/region/region.view.model';
import { ScreenheadViewModel } from 'libs/core/src/lib/model/view-model/screen/screen-head.view.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { ScreeningViewModel } from 'libs/core/src/lib/model/view-model/screening/screening.view.model';
import { Category } from './category';
import { Like } from './like';
import { Media, MediaDetailsSize } from './media';
import { Membership } from './membership';
import { Package } from './package';
import { Post } from './post';
import { Product } from './product';
import { ProductList } from './product-list';
import { AuditoriumViewModel } from 'libs/core/src/lib/model/view-model/auditorium/auditorium.view.model';
import { getGuidOrNull, getRandomIndex, replaceNewLinesWithBr } from '../function/custom-function';
import { IAdditionalFeature } from '../model/interface/additional-feature.interface';
import { TagGroupViewModel } from '../model/view-model/tag-group.view.model';
import { TagGroupApiModel } from '../model/api-model/tag-group.api.model';

@Injectable({
  providedIn: 'root',
})
export class CinemaAdapter implements Adapter<CinemaViewModel> {
  adapt(item: any): CinemaViewModel {
    if (!item.custom_fields) {
      return null;
    }

    const cinemaApiModel = Object.assign(new CinemaApiModel(), item.custom_fields.raw_data);
    const cinemaViewModel = Object.assign(new CinemaViewModel(cinemaApiModel), {
      id: getGuidOrNull(item.custom_fields.cinema_id),
      active: item.custom_fields.active,
      name: item.custom_fields.cinema_name,
      description: item.custom_fields.cinema_description,
      regionId: getGuidOrNull(item.custom_fields.region_id),
      webUrl: item.custom_fields.web_url,
      mobileUrl: item.custom_fields.mobile_url,
    });

    return cinemaViewModel;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RegionAdapter implements Adapter<RegionViewModel> {
  adapt(item: any): RegionViewModel {
    if (!item.custom_fields) {
      return null;
    }

    const regionApiModel = Object.assign(new RegionApiModel(), {
      id: getGuidOrNull(item.custom_fields.region_id ?? item.custom_fields.raw_data.id),
      region: item.custom_fields.title ?? item.custom_fields.raw_data.region,
      code: item.custom_fields.raw_data.code,
      cinemas: (item.custom_fields.active_cinemas ?? (item.custom_fields.raw_data.cinemas as any[]))
        .map((cinema: CinemaApiModel) => new CinemaViewModel(cinema))
        .filter((x) => getGuidOrNull(x.id)),
      webUrl: item.custom_fields.web_url,
      mobileUrl: item.custom_fields.mobile_url,
    });

    const regionViewModel = new RegionViewModel(regionApiModel);
    regionViewModel.defaultCinemaId = getGuidOrNull(item.custom_fields.default_cinema_id);
    regionViewModel.webUrl = regionApiModel.webUrl;

    return regionViewModel;
  }
}

@Injectable({
  providedIn: 'root',
})
export class EventAdapter implements Adapter<EventViewModel> {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {}

  adapt(item: any): EventViewModel {
    const event = new EventViewModel(item.custom_fields.event_raw_data);
    if (item.custom_fields.background_image) {
      event.pictures.push(item.custom_fields.background_image);
    }
    event.promote_outside_schedule = item.custom_fields.promote_outside_schedule;

    event.tagGroups = item.custom_fields.event_raw_data?.tagGroups?.map((item) => new TagGroupViewModel(item));

    const result = Object.assign(event, {
      name: item.custom_fields.title,
      description: item.custom_fields.description,
      likes: item.custom_fields.event_likes,
      likeByUser: item.custom_fields.is_event_liked_by_user,
      screenId: item.custom_fields.event_raw_data ? getGuidOrNull(item.custom_fields.event_raw_data.screenId) : '',
      link: item.link,
      priority: item.custom_fields.event_priority ? parseInt(item.custom_fields.event_priority) : Number.MAX_SAFE_INTEGER,
    });

    if (result.posters?.length) {
      result.randomPosterOrDefault = result.posters[getRandomIndex(result.posters.length)];
    }

    return result;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CategoryAdapter implements Adapter<Category> {
  adapt(item: any): Category {
    return new Category(item.id, item.code, item.name, item.link);
  }
}

@Injectable({
  providedIn: 'root',
})
export class LikeAdapter implements Adapter<Like> {
  adapt(item: any): Like {
    return new Like(item.likes, item.liked_by_user || item.action === 'inserted');
  }
}

@Injectable({
  providedIn: 'root',
})
export class MediaAdapter implements Adapter<Media> {
  adapt(item: any): Media {
    return new Media(
      item.id,
      item.slug,
      item.link,
      item.alt_text,
      item.mime_type,
      item.source_url,

      item.media_details.width,
      item.media_details.height,
      item.media_details.file,

      new MediaDetailsSize(
        item.media_details.sizes.medium.file,
        item.media_details.sizes.medium.width,
        item.media_details.sizes.medium.height,
        item.media_details.sizes.medium.mime_type,
        item.media_details.sizes.medium.source_url
      ),
      new MediaDetailsSize(
        item.media_details.sizes.thumbnail.file,
        item.media_details.sizes.thumbnail.width,
        item.media_details.sizes.thumbnail.height,
        item.media_details.sizes.thumbnail.mime_type,
        item.media_details.sizes.thumbnail.source_url
      ),
      new MediaDetailsSize(
        item.media_details.sizes.full.file,
        item.media_details.sizes.full.width,
        item.media_details.sizes.full.height,
        item.media_details.sizes.full.mime_type,
        item.media_details.sizes.full.source_url
      )
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class MembershipAdapter implements Adapter<Membership> {
  adapt(item: any): Membership {
    return new Membership(
      getGuidOrNull(item.card_type_id),
      getGuidOrNull(item.voucher_id),
      getGuidOrNull(item.voucher_item_id),
      item.can_be_reserved,
      item.link_to_description
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class MovieAdapter implements Adapter<MovieViewModel> {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {}

  adapt(item: any, customOptions: CustomOptions): MovieViewModel {
    const movieRawModel = item.custom_fields.movie_raw_data;
    const movieApiModel: MovieApiModel = Object.assign(new MovieApiModel(), movieRawModel);

    movieApiModel.title = item.custom_fields.title;
    if (movieApiModel.originalTitle === '') {
      movieApiModel.originalTitle = movieApiModel.title;
    }

    movieApiModel.director = item.custom_fields.movie_regie;
    movieApiModel.filmCast = item.custom_fields.movie_actors; //separated by [, ]
    movieApiModel.tagGroups = movieRawModel?.tagGroups?.map((item) => item as TagGroupApiModel);
    if (item.custom_fields.description) {
      movieApiModel.description = item.custom_fields.description;
    }
    if (customOptions?.replaceNewLinesWithBr) {
      movieApiModel.description = replaceNewLinesWithBr(movieApiModel.description);
    }

    movieApiModel.imdbRating = item.custom_fields.imdb_rating;
    movieApiModel.posters = [...movieApiModel.posters, item.custom_fields.poster];

    const movieViewModel = new MovieViewModel(movieApiModel);
    movieViewModel.slug = item.slug;
    movieViewModel.link = item.link;

    movieViewModel.trailerThumbnail = item.custom_fields.movie_trailer_thumbnail;
    movieViewModel.preroll = item.custom_fields.preroll;
    movieViewModel.preroll_skip_time = item.custom_fields.preroll_skip_time;
    movieViewModel.promote_outside_schedule = item.custom_fields.promote_outside_schedule;

    movieViewModel.likes = item.custom_fields.movie_likes;
    movieViewModel.likeByUser = item.custom_fields.is_movie_liked_by_user;

    movieViewModel.priority = item.custom_fields.movie_priority ? parseInt(item.custom_fields.movie_priority) : Number.MAX_SAFE_INTEGER;
    movieViewModel.additionalFeatures = item.custom_fields.additional_features?.map(
      (item: any) => ({ name: item.name, image: item.image } as IAdditionalFeature)
    );

    if (movieViewModel.posters?.length) {
      movieViewModel.randomPosterOrDefault = movieViewModel.posters[getRandomIndex(movieViewModel.posters.length)];
    }

    const trailers = groupBy(item.custom_fields.movie_trailers as TrailerApiModel[], 'lang');
    const trailersGroupByLang: KeyValue<TrailerViewModel[]>[] = Object.entries(trailers)
      .filter((x) => x[1])
      .map(
        (x) =>
          new KeyValue<TrailerViewModel[]>(
            x[0],
            x[1].map((t) => new TrailerViewModel(t))
          )
      );

    return Object.assign(movieViewModel, { trailersGroupByLang });
  }
}

@Injectable({
  providedIn: 'root',
})
export class PostAdapter implements Adapter<Post> {
  adapt(item: any): Post {
    return new Post(
      item.id,
      item.slug,
      item.type,
      item.title.rendered,
      item.content ? item.content.rendered : null,
      item.excerpt ? item.excerpt.rendered : null,
      item.link,
      item.featured_media,
      item.custom_fields ?? {}
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class PackageAdapter implements Adapter<Package> {
  adapt(item: any): Package {
    return new Package(item.ticket_type, item.minimal_ticket_quantity, item.maximum_ticket_quantity, item.price, item.title, item.description);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductListAdapter implements Adapter<ProductList> {
  adapt(item: any): ProductList {
    const raw_data: Product[] = item.custom_fields.items
      .filter((f) => this.isJson(f.custom_fields.raw_data))
      .map((x) => plainToInstance(Product, JSON.parse(x.custom_fields.raw_data)));

    return new ProductList(item.id, item.title.rendered, raw_data);
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}

export class ScreeningDetails implements IScreeningDetails {
  constructor(
    public movie: MovieViewModel,
    public event: EventViewModel,
    public moviePrint: MoviePrintViewModel,
    public region: RegionViewModel,
    public cinema: CinemaViewModel,
    public screening: ScreeningViewModel,
    public screen: ScreenheadViewModel | ScreenViewModel
  ) {
    this.adapt();
  }

  regionName: string = '';
  cinemaName: string = '';
  screeningDate: DateTime = null;
  screenNumber: number = null;
  screenName: string = '';
  screenFeature: string = '';
  duration: number = null;
  movieLanguage: string = '';
  movieRelease: string = '';
  posterUrl: string = '';
  title: string = '';
  ageRestrictionValue: string = '';

  adapt() {
    if (this.movie) {
      this.movie = Object.assign(new MovieViewModel(), this.movie);
      this.title = this.movie.title;
      this.duration = this.movie.duration;
      this.posterUrl = this.movie.firstPosterOrDefault;
      this.ageRestrictionValue = this.movie.ageRestriction();
    }
    if (this.event) {
      const poster = this.event.posters?.length ? this.event.posters[0] : null;
      this.posterUrl = poster || this.posterUrl;
    }
    if (this.moviePrint) {
      this.movieLanguage = this.moviePrint.language;
      this.movieRelease = this.moviePrint.release;
    }
    if (this.region) {
      this.regionName = this.region.name;
    }
    if (this.cinema) {
      this.cinemaName = this.cinema.name;
    }
    if (this.screening) {
      this.screeningDate = this.screening.screeningTimeFrom;
    }
    if (this.screen) {
      this.screenNumber = this.screen.number;
      this.screenFeature = this.screen.feature;
      this.screenName = this.screen.name;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuditoriumAdapter implements Adapter<AuditoriumViewModel> {
  adapt(item: any): AuditoriumViewModel {
    return item.custom_fields ? new AuditoriumViewModel(item.custom_fields) : null;
  }
}

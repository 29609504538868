import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-numeric-stepper',
  templateUrl: './numeric-stepper.component.html',
})
export class NumericStepperComponent {
  @Output() quantityEvent = new EventEmitter<number>();
  @Output() quantityChange = new EventEmitter<number>();
  @Input() maxQuantity = 99;
  @Input() minQuantity = 1;
  @Input() inputField = false;
  private _quantity: number = 1;
  @Input() get quantity() {
    return this._quantity;
  }
  set quantity(value: number) {
    this._quantity = +value;
  }

  @Input() step = 1;
  @Input() disabled = false;
  @Input() selectOnFocus = false;

  constructor() {}

  public onInputChanged(event): void {
    this.quantityEvent.emit(this._quantity);
    this.quantityChange.emit(this._quantity);
  }

  public addQuantity(): void {
    if (this.canBeIncrement()) {
      this.changeQuantity(this.step);
      this.quantityEvent.emit(this._quantity);
      this.quantityChange.emit(this._quantity);
    }
  }

  public subQuantity(): void {
    if (this.canBeDecrement()) {
      this.changeQuantity(-this.step);
      this.quantityEvent.emit(this._quantity);
      this.quantityChange.emit(this._quantity);
    }
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (this.disabled) {
      return;
    }

    switch (event.key) {
      case 'ArrowUp':
        this.addQuantity();
        event.preventDefault();
        break;
      case 'ArrowDown':
        this.subQuantity();
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  public canBeDecrement(): boolean {
    return this._quantity - this.step >= this.minQuantity;
  }

  public canBeIncrement(): boolean {
    return this._quantity + this.step <= this.maxQuantity;
  }

  selectInput(event: FocusEvent): void {
    if (!this.selectOnFocus) {
      return;
    }
    const inputElement = event.target as HTMLInputElement;
    inputElement.select();
  }

  private changeQuantity(value: number) {
    this.quantity = this._quantity + value;
  }
}

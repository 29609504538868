import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { plainToInstance } from 'class-transformer';
import { storageKey } from 'libs/core/src/app.const';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { LoadingStatus } from 'libs/core/src/lib/model/loading/loading-status.enum';
import { BasketPageModel } from 'libs/core/src/lib/model/page/basket/basket.page.model';
import { PersonalModel } from 'libs/core/src/lib/model/personal.model';
import { AgreementViewModel } from 'libs/core/src/lib/model/view-model/agreement/agreement.view.model';
import { UserViewModel } from 'libs/core/src/lib/model/view-model/user/user.view.model';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { ENVIRONMENT_TOKEN, OrderStateService } from 'libs/core/src/public-api';
import { map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { BlockPaymentModel } from '../../page/payment/model/block-payment-model';
import { BlockPaymentEnum } from '../../page/payment/model/block-payment.enum';
import { BasketAgreementComponentInterface } from '../basket-agreement/basket-agreement.component';
import { InternationalPhoneConfig } from '../international-phone/international-phone-config';
import { PhoneDefinitionModel } from '../international-phone/model/phone-definition.model';
import { FormDataBuilderInterface } from './interface/form-data.builder.interface';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { ToastrService } from 'ngx-toastr';

@Component({
  template: '',
})
export abstract class PersonalComponent implements OnInit {
  loadingStatusEnum: typeof LoadingStatus = LoadingStatus;

  @ViewChild('basketAgreementComponent') basketAgreementComponent: BasketAgreementComponentInterface;
  @ViewChild('saveBtn') saveBtn: ElementRef;
  @ViewChild('editBtn') editBtn: ElementRef;
  @Input() order: OrderViewModel = null;
  @Input() formErrors: string[];
  @Input() loadingStatus: LoadingStatus;
  @Input() basketPageModel: BasketPageModel = null;
  @Input() feeLabel: ElementRef;
  @Output() onSubmitEvent = new EventEmitter();
  @Output() showLoader = new EventEmitter();
  @Output() hideLoader = new EventEmitter();
  @Output() blockPayment = new EventEmitter();
  @Input() submitButtonShow = true;
  @Input() public formLocked = false;
  public formSubmitAttempt: boolean;
  public personalForm: FormGroup;
  public currentPhoneNumber: string | null = null;
  public internationalPhoneConfig: InternationalPhoneConfig = null;
  public touchedFormControls: { [key: string]: boolean } = {};
  private selectedPhoneDefinition: PhoneDefinitionModel = null;
  public isLogged = false;

  public disabledTaxId = false;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected appService: AppService,
    protected authStateService: AuthStateService,
    protected userDataProvider: UserDataProvider,
    protected toastr: ToastrService,
    protected translate: TranslateService,
    protected stateService: StateService,
    protected orderDataProvider: OrderDataProvider
  ) {
    this.internationalPhoneConfig = {
      inputControlName: 'phone',
      maxLength: environment.constants.maxPhoneNumberLength,
      inputClass: 'form-control',
      trimLeadingZeros: environment.constants.phoneNumberTrimLeadingZeros,
    };
  }

  ngOnInit() {
    this.authStateService.state$.subscribe((authState) => {
      this.isLogged = this.authStateService.userIsLoggedAndTokenIsValid();
      this.createForm();
    });
  }

  createForm() {
    this.personalForm = this.getForm();

    if (this.isLogged) {
      if (this.personalForm.controls['firstname']?.value) this.personalForm.controls['firstname']?.disable();
      if (this.personalForm.controls['lastname']?.value) this.personalForm.controls['lastname']?.disable();
      if (this.personalForm.controls['email']?.value) this.personalForm.controls['email']?.disable();
      if (this.personalForm.controls['emailRepeat']?.value) this.personalForm.controls['emailrepeat']?.disable();
      if (this.personalForm.controls['phone']?.value && !this.personalForm.controls['phone']?.errors) this.personalForm.controls['phone']?.disable();
    }

    this.touchedFormControls = {};

    Object.keys(this.personalForm.controls).forEach((controlName) => {
      this.touchedFormControls[controlName] = false;
    });

    this.personalForm.valueChanges.subscribe((formData) => {
      Object.keys(formData).forEach((formFieldName) => {
        if (formData[formFieldName] && !this.touchedFormControls[formFieldName]) {
          this.touchedFormControls[formFieldName] = true;
        }
      });
    });

    this.afterFormCreated();
  }

  afterFormCreated() {}

  lockForm(): void {
    this.formLocked = true;
  }

  unlockForm(): void {
    this.formLocked = false;
  }

  public onSaveBtnClicked(event: Event): void {
    event.preventDefault();

    if (this.personalForm) {
      if (this.feeLabel && !this.feeLabel.nativeElement.checked) {
        this.blockPayment.emit(new BlockPaymentModel(BlockPaymentEnum.feeAgreement, true));
      }

      this.onSubmit(this.personalForm);

      if (this.personalForm.valid && (!this.feeLabel || this.feeLabel.nativeElement.checked)) {
        this.lockForm();
        this.blockPayment.emit(new BlockPaymentModel(BlockPaymentEnum.editPersonalData, false));
        this.saveBtn.nativeElement.disabled = true;
        this.editBtn.nativeElement.disabled = false;
      }
    }
  }

  public onEditBtnClicked(event: Event): void {
    this.unlockForm();
    this.blockPayment.emit(new BlockPaymentModel(BlockPaymentEnum.editPersonalData, true));
    this.editBtn.nativeElement.disabled = true;
    this.saveBtn.nativeElement.disabled = false;
  }

  onSubmit(form?: FormGroup) {
    if (!form) {
      form = this.personalForm;
    }

    if (form.contains('taxId') && form.get('taxId') && form.get('taxId').value === 'undefined') {
      form.get('taxId').setValue('');
    }

    this.formSubmitAttempt = true;
    Object.keys(form.controls).forEach((key) => {
      form.controls[key].updateValueAndValidity();
    });

    Object.keys(form.controls).forEach((controlName) => {
      this.touchedFormControls[controlName] = true;
    });

    if (form.valid) {
      this.submitPersonalData(form);
    }

    if (this.basketAgreementComponent) {
      this.basketAgreementComponent.valid();
    }
  }

  onSubmitObservable(form?: FormGroup): Observable<boolean> {
    if (!form) {
      form = this.personalForm;
    }

    if (form.contains('taxId') && form.get('taxId') && form.get('taxId').value === 'undefined') {
      form.get('taxId').setValue('');
    }

    this.formSubmitAttempt = true;

    Object.keys(form.controls).forEach((key) => {
      form.controls[key].updateValueAndValidity();
    });

    Object.keys(form.controls).forEach((controlName) => {
      this.touchedFormControls[controlName] = true;
    });

    if (form.invalid) {
      return of(false);
    }

    let formData = form.getRawValue() as object;
    let personalModel = plainToInstance(PersonalModel, formData, { strategy: 'excludeAll' });
    personalModel.firstname = form.get('name')?.value;

    let result = false;

    return this.submitPersonalDataObservable(personalModel).pipe(
      tap({
        next: () => {
          if (this.basketAgreementComponent) {
            this.basketAgreementComponent.valid();
          }
          result = true;
        },
      }),
      map(() => {
        return result;
      })
    );
  }

  submitPersonalDataObservable(personalModel: PersonalModel): Observable<OrderViewModel> {
    const order = this.order.toApiModel();
    order.userEmail = personalModel.email;
    order.userFirstName = personalModel.firstname;
    order.userLastName = personalModel.lastname;
    order.userPhone = personalModel.phone;
    order.taxId = personalModel.taxId;
    order.paymentMethods = [];
    return this.orderDataProvider.update(this.order.cinemaId, order);
  }

  createAccountIfNeeded(form: FormGroup): Observable<UserViewModel> {
    if (!form.controls['autoRegisterAccount'].value && !form.controls['manualRegisterAccount'].value) {
      return of(null);
    }

    const user: UserViewModel = Object.assign(new UserViewModel(), {
      ...form.value,
      firstName: form.controls['firstname'].value,
      lastName: form.controls['lastname'].value,
      password: form.controls['registerAccountPassword'].value,
      agreements: form.controls['agreements'].value.filter((a) => a.checked).map((a) => a.id),
    });

    return this.userDataProvider.create(user);
  }

  submitPersonalData(form: FormGroup) {
    const formData = form.getRawValue();
    if (this.basketAgreementComponent) {
      this.saveToStorage(form.get('emailRepeat').value, this.basketAgreementComponent.agreements, form.get('taxId').value);
    }

    this.onSubmitEvent.emit(plainToInstance(PersonalModel, formData as object, { strategy: 'excludeAll' }));
  }

  protected getInitialPersonalData(): PersonalModel {
    const person = new PersonalModel();

    if (this.isLogged) {
      const user = this.authStateService.getUser();
      return Object.assign(person, {
        firstname: user.firstName,
        lastname: user.lastName,
        phone: user.phone,
        email: user.email,
        emailRepeat: user.email,
        agreement: false,
        name: user.firstName,
      });
    }

    if (this.order?.userFirstName !== null) {
      return Object.assign(person, {
        firstname: this.order.userFirstName,
        lastname: this.order.userLastName,
        phone: this.order.userPhone,
        email: this.order.userEmail,
        emailRepeat: this.order.userEmail,
        agreement: false,
      });
    }

    if (this.stateService.getItem(storageKey.personalData)) {
      return JSON.parse(this.stateService.getItem(storageKey.personalData));
    }

    return person;
  }

  protected getForm(): FormGroup {
    const formData = {
      user: this.getInitialPersonalData(),
      agreements: this.getAgreements(),
    };

    return this.getFormGroup(formData);
  }

  abstract getFormGroup(formDataBuilderInterface: FormDataBuilderInterface): FormGroup;

  public onChangedPhoneDefinition(phoneDefinition: PhoneDefinitionModel) {
    this.selectedPhoneDefinition = phoneDefinition;
  }

  public onPhoneNumberChanged(phoneNumber: string): void {
    this.currentPhoneNumber = phoneNumber;
  }

  private getAgreements() {
    const user = this.authStateService.getUser();

    if (this.basketPageModel) {
      this.basketPageModel.agreements = this.basketPageModel.agreements
        ?.sort(function (x, y) {
          const xNum = x.required ? 1 : 0;
          const yNum = y.required ? 1 : 0;
          return yNum - xNum;
        })
        .map((agreement) => {
          agreement.checked = user?.agreements?.some((agr) => agr.toLowerCase() === agreement.id.toLowerCase()) ?? false;
          return agreement;
        });
    }

    return this.basketPageModel ? this.basketPageModel.agreements : [];
  }

  private saveToStorage(personalEmail: string, agreements: AgreementViewModel[], personalTaxId: string) {
    this.stateService.setItem(storageKey.personalEmail, personalEmail);
    const selectedAgreements = agreements.filter((agreement) => agreement.checked && agreement);
    this.stateService.setItem(storageKey.personalAgreements, JSON.stringify(selectedAgreements));

    if (personalTaxId) {
      this.stateService.setItem(storageKey.personalTaxId, personalTaxId);
    } else {
      this.stateService.removeItem(storageKey.personalTaxId);
    }
  }

  protected setAutoRegisterAccount(value: boolean) {
    this.submitButtonShow = true;

    this.personalForm.updateValueAndValidity();
    this.personalForm.controls['autoRegisterAccount'].setValue(value);
  }

  onRegisterClicked(event) {
    this.setAutoRegisterAccount(true);
  }

  onBuyWithoutRegistrationClicked(event) {
    this.setAutoRegisterAccount(false);
  }
}

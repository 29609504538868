<div class="numeric-stepper d-flex align-items-center justify-content-between w-100">
  <ng-container *ngIf="!disabled; else staticMinusButtonTemplate">
    <i class="fa fa-minus px-3" [ngClass]="{'active': canBeDecrement(), 'pointer': canBeDecrement()}"
       (click)="subQuantity()"></i>
  </ng-container>
  <ng-container *ngIf="inputField && !disabled; else staticValueTemplate">
    <input digitOnly class="p-0 text-center" type="text" [(ngModel)]="quantity" (input)="onInputChanged($event)"
           (focus)="selectInput($event)" (keydown)="onKeyDown($event)" [size]="maxQuantity.toString().length"
           [maxLength]="maxQuantity.toString().length" [disabled]="disabled">
  </ng-container>
  <ng-template #staticValueTemplate>
    <span class="px-sm-2">{{ quantity }}</span>
  </ng-template>
  <ng-container *ngIf="!disabled; else staticPlusButtonTemplate">
    <i class="fa fa-plus px-3" [ngClass]="{'active': canBeIncrement(), 'pointer': canBeIncrement()}"
       (click)="addQuantity()"></i>
  </ng-container>
</div>
<ng-content></ng-content>

<ng-template #staticMinusButtonTemplate>
  <i class="fa fa-minus px-3"></i>
</ng-template>

<ng-template #staticPlusButtonTemplate>
  <i class="fa fa-plus px-3"></i>
</ng-template>
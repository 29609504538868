import { Component } from '@angular/core';
import { OrderTicketsSummaryComponent } from 'libs/shared/src/lib/component/order/tickets-summary/tickets-summary.component';
import { environment } from 'apps/kinoteka/src/environments/environment';

@Component({
  selector: 'app-order-tickets-summary',
  templateUrl: './tickets-summary.component.html',
})
export class CustomOrderTicketsSummaryComponent extends OrderTicketsSummaryComponent {
  env = environment;
}

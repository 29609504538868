import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { PaymentConfigViewModel } from 'libs/core/src/lib/model/view-model/payment/config/payment.config.view.model';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { Observable } from 'rxjs';
import { PaymentProviderEvent, WorkPaymentProviderEvent, DonePaymentProviderEvent } from '../../event/payment-provider.event';
import { OrderPaymentPackage } from '../../../../../../../../core/src/lib/model/order-payment.package.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';

@Component({
  selector: 'app-payment-provider-vivawallet-component',
  templateUrl: './vivawallet-payment-provider.component.html',
})
export class VivaWalletPaymentProviderComponent implements PaymentProviderComponentInterface {
  @Output() public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  public readonly paymentProviderIdentifier: string = 'vivawallet';
  public paymentProviderConfig: PaymentConfigViewModel | null;
  public resourceUrl: string = null;
  public returnUrl: string = null;
  private readonly paymentChannel: string | null = null;

  public constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected appService: AppService) {
    this.paymentChannel = environment.constants.paymentChannel;
  }

  onPreInitPayment(event: OrderPaymentPackage): Observable<OrderPaymentPackage> {
    return new Observable<OrderPaymentPackage>((subscriber) => {
      subscriber.next(event);
      subscriber.complete();
    });
  }

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    this.resourceUrl = paymentModel.plainPayload;
    this.events.next(new WorkPaymentProviderEvent());

    // var form = document.createElement('form');
    // form.setAttribute('method', 'post');
    // form.setAttribute('action', paymentModel.plainPayload);
    // form.setAttribute('target', '_top');
    // document.body.appendChild(form);
    // form.submit();

    window.location.href = paymentModel.plainPayload;
  }

  public onLoad() {
    this.events.next(new DonePaymentProviderEvent());
  }
}

import { MyAccountPageEnum } from './lib/enum/my-account-page.enum';

export const appProjectName = {
  HELIOS: 'helios',
  SCHULMAN: 'schulman',
  MUVI: 'muvi',
  ARENA: 'arena',
  BLUE: 'blue',
  VIOLET_CROWN: 'violetcrown',
  LANDMARK: 'landmark',
  CINEPOLIS: 'cinepolis',
  ONEIL: 'oneil',
  CINESTAR: 'cinestar',
  CINELAND: 'cineland',
  MILFORD: 'milford',
  MILFORD_MAINSTREET: 'milford-mainstreet',
  MILFORD_WESTOWN: 'milford-westown',
  KINOTEKA: 'kinoteka',
  PROJECTOR: 'projector',
  CINEGRAND: 'cinegrand',
  MTSDVORANA: 'mts-dvorana',
  ODISEJADA: 'odisejada',
};

export const APP_CONFIG_PATH = '/assets/config.json';

export const storageKey = {
  appConfig: 'positivecinema.app.config',
  order: 'positivecinema.order',
  chosenLocation: 'locationId',
  selectedPaymentMethod: 'selectedPaymentMethod',
  vouchers: 'vouchers',
  personalEmail: 'personalEmail',
  personalAgreements: 'personalAgreements',
  isExtraFeesSelected: 'isExtraFeesSelected',
  personalTaxId: 'personalTaxId',
  lang: 'lang',
  prevLang: 'prevLang',
  backUrl: 'backUrl',
  isDream: 'isDream',
  flowType: 'flow_type',
  flowData: 'flow_data',
  isEvent: 'isEvent',
  chosenDate: 'chosenDate',
  lastScreeningId: 'lastScreeningId',
  parentOrder: 'parentOrder',
  lastEventId: 'lastEventId',
  personalData: 'personalData',
  redirectImNappLa: 'redirectIm_napp_la',
  sessionScreeningService: 'SessionScreeningService',
  myAccountSelectedOption: 'myAccountSelectedOption',
  reservationId: 'res_id',
  reservationCinemaId: 'res_cid',
  reservationScreeningId: 'res_sid',
  reservationScreeningTimeTo: 'res_stt',
  membershipLevel: 'membershipLevel',
  filteredCinema: 'filteredCinemaId',
  mgmPromotionId: 'mgmPromotionId',
  embededPaymentUrl: 'embededPayment',
  orderAgreements: 'orderAgreements',
  cateringEnabled: 'cateringEnabled',
  cinemaDayOffset: 'cinemaDayOffset',
  selectedDate: 'selectedDate',
  timeStampName: 'secondsFromEpoch_',
  lastEpochName: 'lastEpochTime_',
  token: 'token',
  needCompleteOUD: 'needCompleteOUD',
  table: 'table',
  lastUrl: 'lastUrl',
};

export const storageDeprecatedKey = {
  orderId: 'orderId',
  chosenRegion: 'regionId',
  chosenCinema: 'cinemaId',
};

/*
Hasło musi zawierać:
Przynajmniej jedną literę.
Przynajmniej jedną cyfrę.
Przynajmniej jeden znak specjalny (np. !, @, #, _).
Musi mieć od 8 do 100 znaków.
*/
export const passwordWithSpecialCharAndNumberValidator = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W|_])[A-Za-z\d\W|_]{8,100}$/;

/*
Hasło musi zawierać:
Przynajmniej jedną literę.
Przynajmniej jedną cyfrę.
Musi zaczynać się literą lub cyfrą.
Długość hasła musi wynosić od 8 do 100 znaków (bo pierwsze dopasowanie dodaje 1 znak).
*/
export const passwordWithLetterAndNumberValidator = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d].{7,99}$/;

/*
Hasło musi zawierać przynajmniej jedną wielką literę.
Musi mieć przynajmniej jedną małą literę.
Musi zawierać przynajmniej jedną cyfrę.
Musi zawierać przynajmniej jeden znak specjalny.
Musi mieć od 8 do 100 znaków.
*/
export const strictPasswordWithSpecialCharValidator = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W]).{8,100}$/;

/*
Hasło musi zawierać przynajmniej jedną wielką literę.
Musi mieć przynajmniej jedną małą literę.
Musi zawierać przynajmniej jedną cyfrę.
Musi zawierać przynajmniej jeden znak specjalny (dowolny, który nie jest literą ani cyfrą).
Musi mieć od 8 do 100 znaków.
*/
export const passwordWithCharRangeAndSpecialValidator = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,100}$/;

export const validationPattern = {
  password: passwordWithLetterAndNumberValidator,
  phone: /^(?=.{0,15}$|\+.{0,15}$)[+]?[0-9]+(?:-[0-9]*)*$/,
  email: /^(?!.*\.\.)(?!.*\.$)(?!^\.)(?!.*\.@)(?!.*@\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
};

export const heliosValidationPattern = {
  password: passwordWithSpecialCharAndNumberValidator,
  phone: /^(?=.{0,15}$)[+]?[0-9]{9}[0-9]{0,5}$/,
  email: /^(?!.*\.\.)(?!.*\.$)(?!^\.)(?!.*\.@)(?!.*@\.)[a-zA-Z0-9._%+-ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
};

export const oneilValidationPattern = {
  password: strictPasswordWithSpecialCharValidator,
  phone: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  email: /^(?!.*\.\.)(?!.*\.$)(?!^\.)(?!.*\.@)(?!.*@\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
};

export const kinotekaValidationPattern = {
  password: passwordWithSpecialCharAndNumberValidator,
  phone: /^(?=.{0,15}$)[+]?[0-9]{9}[0-9]{0,5}$/,
  email: /^(?!.*\.\.)(?!.*\.$)(?!^\.)(?!.*\.@)(?!.*@\.)[a-zA-Z0-9._%+-ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
};

export const identities = {
  ticketServiceFeeId: '0148384a-6c02-48d3-ab48-27b3b1af8414',
  loyaltyPointsPaymentType: '922b3024-5a2f-4ab1-a11f-f448257cf7f7',
};

export const cookieKey = {
  regionId: 'regionId',
  external_user_token: 'external_user_token',
  external_user_r_token: 'external_user_r_token',
};

export const types = {
  rewardsFlowItemType: 4,
};

export const cardType = {
  loyalty: { value: 1, name: 'Loyalty' },
  discount: { value: 2, name: 'Discount' },
  prepaid: { value: 4, name: 'Prepaid' },
  membership: { value: 16, name: 'Membership' },
  gift: { value: 32, name: 'Gift Card' },
};

export const myAccountOptionsConsts = {
  defaultOption: MyAccountPageEnum.OVER_VIEW,
  availableOptions: [
    { page: MyAccountPageEnum.OVER_VIEW, useInMenu: true, order: 1 },
    { page: MyAccountPageEnum.TICKETS, useInMenu: true, order: 2 },
    { page: MyAccountPageEnum.RESERVATIONS, useInMenu: true, order: 3 },
    { page: MyAccountPageEnum.VOUCHERS, useInMenu: true, order: 4 },
    { page: MyAccountPageEnum.GIFT_CARDS, useInMenu: true, order: 5 },
    { page: MyAccountPageEnum.PREPAID_CARDS, useInMenu: true, order: 6 },
    { page: MyAccountPageEnum.CLUB_REWARDS, useInMenu: true, order: 7 },
    { page: MyAccountPageEnum.WATCH_LIST, useInMenu: true, order: 8 },
    { page: MyAccountPageEnum.SETTINGS, useInMenu: true, order: 9 },
    { page: MyAccountPageEnum.VOUCHERS_PASSES, useInMenu: true, order: 10 },
    { page: MyAccountPageEnum.MEMBER_GET_MEMBER, useInMenu: false, order: 11 },
    { page: MyAccountPageEnum.VOUCHERS_FLAT_RATES, useInMenu: false, order: 12 },
    { page: MyAccountPageEnum.REWARDS, useInMenu: false, order: 13 },
    { page: MyAccountPageEnum.TRANSACTIONS, useInMenu: false, order: 14 },
  ],
};

export const currentlyActive = 'currently-active';

export enum errorCodeEnum {
  TICKET_LIMIT_EXCEEDED = '122',
  GIFT_CARD_NOT_FOUND = '123',
  GIFT_CARD_ALREADY_LOCKED = '279',
  GIFT_CARD_USED_ON_ORDER = '10042',
}

export const GUID_LENGTH = 36;

export const possibleRegionParams = ['regionid', 'rid'];
export const possibleCinemaParams = ['cinemaid', 'cid'];

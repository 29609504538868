import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { PaymentMethodEnum } from '../payu/payment-method.enum';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { PaymentProviderStateService } from '../../../service/payment-provider-state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { PaymentHelperService } from '../../../service/payment-helper.service';
import { PaymentProviderEvent, WorkPaymentProviderEvent, ErrorPaymentKeyProviderEvent } from '../../event/payment-provider.event';
import { OrderPaymentPackage } from '../../../../../../../../core/src/lib/model/order-payment.package.model';
import { CreatePaymentRedirectUrl } from '../../../create-payment-redirect-url';
import { PaymentProviderPayMethodViewModel } from 'libs/core/src/lib/model/view-model/payment-provider-pay-method.view.model';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { generalPaymentError } from '../../../payment-error.consts';

@Component({
  selector: 'app-payment-provider-conotoxia-component',
  templateUrl: './conotoxia-payment-provider.component.html',
})
export class ConotoxiaPaymentProviderComponent implements PaymentProviderComponentInterface, OnInit, OnDestroy {
  public selectedPayMethodTypeCustom: string | null = null;
  public paymentMethod = PaymentMethodEnum;
  public readonly paymentProviderIdentifier: string = 'cinkciarz';
  private order: OrderViewModel = null;
  @Output() public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private selectedPaymentTypeService: PaymentProviderStateService,
    private orderStateService: OrderStateService,
    private route: ActivatedRoute,
    private paymentHelperService: PaymentHelperService
  ) {
    this.selectedPaymentTypeService.state$.subscribe((state) => {
      if (state) {
        this.selectedPayMethodTypeCustom = state.type;
      } else {
        this.selectedPayMethodTypeCustom = null;
      }
    });
  }

  ngOnInit() {
    this.order = this.orderStateService.getOrder();
    this.events.next(new WorkPaymentProviderEvent());
  }

  ngOnDestroy(): void {}

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_CTX) {
      window.location.href = paymentModel.plainPayload;
    }
  }

  public onPreInitPayment(event: OrderPaymentPackage): Observable<OrderPaymentPackage> {
    return new Observable<OrderPaymentPackage>((subscriber) => {
      if (this.selectedPayMethodTypeCustom === null) {
        event.abort = true;
        this.emitNotSelectedPaymentProvider();
        subscriber.next(event);
        subscriber.complete();
      }

      if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_CTX) {
        const request: CreatePaymentRedirectUrl = new CreatePaymentRedirectUrl();
        request.paymentChannel = this.environment.constants.paymentChannel;
        request.order = this.order;
        request.route = this.route;
        event.continueUrl = decodeURIComponent(this.paymentHelperService.createTarget(request));
        subscriber.next(event);
        subscriber.complete();
      }
    });
  }

  get conotoxiaPaymentMethod() {
    const conotoxiaModel = new PaymentProviderPayMethodViewModel();
    conotoxiaModel.id = 'cinkciarz';
    conotoxiaModel.imageUrl = this.getDefaultPaymentImageUrl();
    return conotoxiaModel;
  }

  getDefaultPaymentImageUrl() {
    return `/assets/images/cinkciarz_pay.png`;
  }

  private emitNotSelectedPaymentProvider() {
    this.events.emit(new ErrorPaymentKeyProviderEvent(generalPaymentError.NotSelectedPaymentProvider));
  }
}

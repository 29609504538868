import { Injectable } from '@angular/core';
import { IAccountItem } from 'libs/shared/src/lib/component/account-items/account-items.component';
import { combineLatest, iif, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { UserDataProvider } from '../data-provider/user.data-provider';
import { UserLoyaltyPopupTypeEnum } from '../model/enum/user-loyalty-popup-type';
import { CardViewModel } from '../model/view-model/account-items/card/card.view.model';
import { AccountItemsVoucherViewModel } from '../model/view-model/account-items/voucher/account-items-voucher.view.model';
import { AuthStateService } from '../state/auth.state.service';
import { OrderStateService } from '../state/order.state.service';
import { CardPaymentService } from './card-payment.service';
import { DateTimeService } from './datetime.service';

export interface IAccountItemsObject {
  items: IAccountItem[];
  vouchers: IAccountItem[];
  giftCards: IAccountItem[];
  prepaids: IAccountItem[];
}

export interface UserLoyaltyPopupObject {
  userLoyaltyPopupType: UserLoyaltyPopupTypeEnum;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoyaltyService {
  private errorState = new Subject<UserLoyaltyPopupObject>();
  errorState$ = this.errorState.asObservable();

  constructor(
    protected orderStateService: OrderStateService,
    protected authStateService: AuthStateService,
    protected userDataProvider: UserDataProvider,
    protected dateTimeService: DateTimeService,
    protected cardPaymentService: CardPaymentService
  ) {}

  showError(userLoyaltyPopupType: UserLoyaltyPopupTypeEnum, message?: string) {
    this.errorState.next({ userLoyaltyPopupType: userLoyaltyPopupType, message: message });
  }

  getAccountItems(): Observable<IAccountItemsObject> {
    return combineLatest([this.orderStateService.state$, this.authStateService.isLogged$()], (order, userIsLogged) => ({ order, userIsLogged })).pipe(
      switchMap((m) =>
        iif(
          () => !m.userIsLogged,
          of(null),
          this.userDataProvider.getAccountItems().pipe(
            map((data) => {
              return [
                ...data.vouchers.map((item) => this.makeVoucher(item, 'voucher')),
                ...data.cards.filter((c) => !c.expiryDate || !this.dateTimeService.isPast(c.expiryDate)).map((item) => this.makeCard(item, 'card')),
              ];
            }),
            tap((t) => this.checkUsedItems(t)),
            map((m) => {
              return {
                items: m,
                vouchers: m.filter((f) => f.modelType === 'voucher'),
                giftCards: m.filter(
                  (f) => f.modelType === 'card' && this.cardPaymentService.isGiftCard(f.model?.type) && !this.cardPaymentService.isEmpty(f?.model)
                ),
                prepaids: m.filter(
                  (f) => f.modelType === 'card' && this.cardPaymentService.isPrepaid(f.model?.type) && !this.cardPaymentService.isEmpty(f?.model)
                ),
              };
            })
          )
        )
      )
    );
  }

  makeVoucher(model: AccountItemsVoucherViewModel, type: string): IAccountItem {
    return {
      model: model,
      modelType: type,
      name: model.name,
      description: model.description,
      expiryDate: model.expiryDate,
    } as IAccountItem;
  }

  makeCard(model: CardViewModel, type: string): IAccountItem {
    return {
      model: model,
      modelType: type,
      name: model.name,
      description: model.description,
      expiryDate: model.expiryDate,
    } as IAccountItem;
  }

  checkUsedItems(items: IAccountItem[]) {
    const order = this.orderStateService.getOrder();
    items.forEach(
      (i) =>
        (i.checked =
          i.modelType === 'voucher'
            ? order?.getVouchers().some((v) => v.number === i.model.number)
            : Boolean(order?.paymentMethods?.find((paymentMethod) => paymentMethod.cardId === i.model.id)))
    );
  }
}

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { Observable } from 'rxjs';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { OrderPaymentPackage } from '../../../../../../../../core/src/lib/model/order-payment.package.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';

@Component({
  selector: 'app-payment-provider-payu-blik-component',
  templateUrl: './payu-blik-payment-provider.component.html',
})
export class PayuBlikPaymentProviderComponent implements PaymentProviderComponentInterface, OnInit, OnDestroy {
  @Output()
  public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  public inputCode: string;

  public ngOnDestroy(): void {}

  public ngOnInit(): void {}

  public onPostInitPayment(paymentModel: PaymentViewModel): void {}

  public onPreInitPayment(event: OrderPaymentPackage): Observable<OrderPaymentPackage> {
    return new Observable<OrderPaymentPackage>((subscriber) => {
      event.intPayMethodValue = this.inputCode;
      subscriber.next(event);
      subscriber.complete();
    });
  }
}
